import { useState } from 'react'

import { Button } from 'mmfintech-portal-commons'
import { CheckBoxGroup } from '../../../components'
import { TransactionsTableColumns } from '../../../@types'
import { TableCustomizeTitle, TableCustomizeWrapper } from './CustomizeTableModal.styled'

import { tr } from 'mmfintech-commons'

type CustomizeTableModalProps = {
  columns: TransactionsTableColumns
  onClose: () => void
}

export const CustomizeTableModal = ({ columns, onClose }: CustomizeTableModalProps) => {
  const [visible, setVisible] = useState<string[]>(columns.visible)

  const toggleVisible = (name: string): void => {
    if (visible.includes(name)) {
      setVisible(prevState => prevState.filter((v: string) => v !== name))
    } else {
      setVisible(prevState => [...prevState, name])
    }
  }

  const handleApplyClick = () => {
    columns.setVisible(visible)
    onClose()
  }

  return (
    <TableCustomizeWrapper data-test='customize-transactions-table-modal'>
      <TableCustomizeTitle className='title'>
        {tr('FRONTEND.TRANSACTIONS.COLUMNS.CUSTOMIZE_TITLE', 'Customize Table')}
      </TableCustomizeTitle>
      <CheckBoxGroup
        items={columns.meta}
        checked={name => visible.includes(name)}
        setChecked={toggleVisible}
        footer={
          <Button
            type='button'
            color='round-secondary'
            text={tr('FRONTEND.TRANSACTIONS.COLUMNS.APPLY_BUTTON', 'Apply')}
            onClick={handleApplyClick}
          />
        }
      />
    </TableCustomizeWrapper>
  )
}
