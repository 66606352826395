import styled from 'styled-components'

export const AccountOverviewRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 5rem 1fr 1fr 1fr 1fr;
  font-size: 16px;
  color: white;
  background: #2c2c2c;
  box-shadow: 0 0.4rem 3rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 1.5rem;
  margin-bottom: 1rem;

  .cell {
    display: grid;

    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #808080;
    }
  }

  .currencyIcon {
    border: 1px solid white;
  }
`
export const AccountOverviewListWrapper = styled.div`
  margin-bottom: 4rem;
`
