import { useState } from 'react'

import { LogoButton, LogoWrapper } from './MerchantLogo.styled'
import { ErrorDisplay, FileInput, Preloader } from 'mmfintech-portal-commons'

import { useMerchantLogo } from 'mmfintech-backend-api'
import { checkImageUpload, tr } from 'mmfintech-commons'

import IconLogoRemove from '../../images/icons/logo-delete.svg?react'

type MerchantLogoProps = {
  readonly?: boolean
}

export const MerchantLogo = ({ readonly }: MerchantLogoProps) => {
  const [error, setError] = useState(null)

  const { logo, logoError, logoFetching, deleteLogo, deleteLogoError, updateLogo, updateLogoError } = useMerchantLogo()

  const reset = () => {
    setError(null)
  }

  const convertFileToBase64 = (file: any) => {
    const reader = new FileReader()
    return new Promise(resolve => {
      reader.addEventListener('load', () => resolve(String(reader.result)))
      reader.readAsDataURL(file)
    })
  }

  const doUpdateLogo = async (fileData: any) => {
    try {
      await updateLogo(fileData as string).unwrap()
    } catch (e) {
      return e
    }
  }

  const handleChange = (list: any) => {
    reset()
    convertFileToBase64(list[0]).then(doUpdateLogo)
  }

  const handleDelete = async () => {
    reset()
    try {
      await deleteLogo().unwrap()
    } catch (e) {
      return e
    }
  }

  const handleError = (errors: any) => setError(checkImageUpload(errors))

  return (
    <>
      <LogoWrapper className='logo-wrapper'>
        {logoFetching ? (
          <div className='logo-loader'>
            <Preloader />
          </div>
        ) : (
          <div className='logo-inner'>
            {readonly ? (
              <LogoButton className='image-item'>
                {logo ? <img src={logo} alt='' data-test='logo-image' /> : null}
              </LogoButton>
            ) : (
              <FileInput
                maxNumber={1}
                onChange={handleChange}
                onError={handleError}
                maxFileSize={1024 * 1024}
                acceptType={['jpg', 'jpeg', 'gif', 'png', 'svg']}>
                {({ onFileUpload }) =>
                  logo ? (
                    <LogoButton className='image-item'>
                      <img src={logo} alt='' onClick={onFileUpload} data-test='logo-image' />
                      <div className='logo-remove-item' onClick={handleDelete} data-test='logo-delete-button'>
                        <IconLogoRemove />
                      </div>
                    </LogoButton>
                  ) : (
                    <LogoButton className='bordered' onClick={onFileUpload}>
                      <span className='caption'>{tr('FRONTEND.PROFILE.LOGO.UPLOAD', 'Upload Logo')}</span>
                    </LogoButton>
                  )
                }
              </FileInput>
            )}
          </div>
        )}
      </LogoWrapper>

      <ErrorDisplay error={[error, logoError, updateLogoError, deleteLogoError]} className='text-center' />
    </>
  )
}
