import { useEffect } from 'react'

import parse from 'html-react-parser'

import { CredentialsStyled } from './CredentialsModal.styled'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'

import { useFormValues } from 'mmfintech-commons'
import { isValidFunction, tr } from 'mmfintech-commons'
import { useEnotasAuthenticateMutation } from 'mmfintech-backend-api'

type CredentialsModalProps = {
  onClose: () => void
  onSuccess?: () => void
}

export const CredentialsModal = ({ onClose, onSuccess }: CredentialsModalProps) => {
  const [authenticate, { isLoading: authenticateFetching, error: authenticateError, reset: authenticateReset }] =
    useEnotasAuthenticateMutation()

  const formValues = useFormValues({
    username: { required: true },
    password: { required: true }
  })

  const handleSubmit = async () => {
    if (formValues.areValid()) {
      try {
        await authenticate(formValues.prepare()).unwrap()
        onClose()
        isValidFunction(onSuccess) && onSuccess()
      } catch (error) {
        return error
      }
    }
  }

  useEffect(() => {
    return () => {
      formValues.reset()
      authenticateReset()
    }
  }, [])

  return (
    <CredentialsStyled data-test='enotas-credentials-modal'>
      <div className='title'>
        {parse(tr('FRONTEND.ENOTAS.CREDENTIALS.TITLE', 'City Hall credentials for issue Nota Fiscal'))}
      </div>

      <ErrorDisplay error={authenticateError} />

      <div className='form-container'>
        <Input
          type='text'
          data-test='username'
          label={tr('FRONTEND.ENOTAS.CREDENTIALS.USERNAME_LABEL', 'City hall username')}
          {...formValues.registerInput('username')}
        />

        <Input
          type='password'
          data-test='password'
          label={tr('FRONTEND.ENOTAS.CREDENTIALS.PASSWORD_LABEL', 'City hall password')}
          {...formValues.registerInput('password')}
          hideShowPassword
        />
      </div>

      <div className='buttons'>
        <Button
          type='button'
          color='round-primary'
          loading={authenticateFetching}
          text={tr('FRONTEND.ENOTAS.CREDENTIALS.SUBMIT_BUTTON', 'Submit')}
          onClick={handleSubmit}
          data-test='button-submit'
        />
      </div>
    </CredentialsStyled>
  )
}
