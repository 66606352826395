import { ActivitiesPane } from './ActivitiesPane'
import { AdminDashboard } from './AdminDashboard'
import { StaffDashboard } from './SraffDashboard'
import { DashboardWrapper } from './Dashboard.styled'
import { NewInvoiceButton } from '../invoices'

import { isOwnerOrAdministrator, selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'

export const Dashboard = () => {
  const customerRole = useAppSelector(selectCurrentUserRole)

  return (
    <DashboardWrapper>
      {isOwnerOrAdministrator(customerRole) ? <AdminDashboard /> : <StaffDashboard />}

      <ActivitiesPane />

      <NewInvoiceButton origin='dashboard' />
    </DashboardWrapper>
  )
}
