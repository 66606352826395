import styled from 'styled-components'

export const FilterWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr auto;
  }
`

export const FilterButtons = styled.div`
  grid-row: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  .button {
    color: #ffffff;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    height: 3.4rem;
  }

  @media (min-width: 640px) {
    justify-content: flex-end;
  }
  @media (min-width: 1024px) {
    padding-top: 2rem;
    grid-row: auto;
  }
`

export const FiltersContainer = styled.div`
  display: flex;
  flex-flow: row wrap;

  .input-wrapper {
    margin-bottom: 4px;
    margin-right: 1.5rem;

    label {
      color: #808080;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 120%;
      margin-bottom: 0.5rem;
    }

    input {
      height: 3.4rem;
      font-size: 1.4rem;
      font-weight: 400;

      &::-webkit-input-placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
      &:-ms-input-placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
      &::placeholder {
        font-size: 1.4rem;
        font-weight: normal;
      }
    }
  }

  [data-test='date-input-from'],
  [data-test='date-input-to'] {
    max-width: 14rem;

    & > div {
      padding-left: 1.5rem;
      padding-right: 1rem;
    }
  }

  [data-test='number-input-id'] {
    max-width: 14rem;
  }
  [data-test='text-input-reference'] {
    max-width: 14rem;
  }
  [data-test='text-input-foreignTransactionId'],
  [data-test='text-input-customerEmail'],
  [data-test='text-input-description'] {
    max-width: 22rem;
  }
  [data-test='text-input-toAmount'],
  [data-test='text-input-fromAmount'] {
    max-width: 14rem;
  }
  [data-test='select-input-statuses'] {
    max-width: 14rem;
  }
  [data-test='select-input-currency'],
  [data-test='select-input-status'] {
    max-width: 10rem;
  }
  [data-test='select-input-paymentMethods'] {
    width: auto;
    min-width: 20rem;
  }
`

export const FilterSelectStyles = {
  container: provided => ({
    ...provided,
    fontFamily: 'inherit',
    fontSize: '1.4rem',
    lineHeight: '120%'
  }),
  control: (provided, { menuIsOpen, selectProps }) => ({
    ...provided,
    cursor: 'pointer',
    color: menuIsOpen ? '#000000' : '#ffffff',
    borderWidth: '1px',
    borderStyle: menuIsOpen ? 'none' : 'solid',
    borderColor: menuIsOpen ? 'none' : selectProps?.error?.length ? '#ff0431' : '#808080',
    borderRadius: menuIsOpen ? '2rem 2rem 0 0' : '2rem',
    background: menuIsOpen ? '#ffffff' : 'transparent',
    minHeight: '3.4rem',
    //paddingLeft: '1rem',
    padding: '0 0 0 1rem',
    transition: 'none',
    boxShadow: 'none',
    outline: 'none',
    ':hover': {
      border: menuIsOpen ? 'none !important' : '1px solid #808080 !important'
    }
  }),
  multiValue: (provided, { menuIsOpen }) => ({
    ...provided,
    background: '#484848',
    color: menuIsOpen ? '#000000' : '#ffffff'
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: 'inherit'
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 8px'
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: '0 8px 0 0'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '0 8px 0 0'
  }),
  singleValue: provided => ({
    ...provided,
    color: 'inherit'
  }),
  input: provided => ({
    ...provided,
    marginBottom: 0,
    marginTop: 0,
    paddingBottom: 0,
    paddingTop: 0
  }),
  placeholder: provided => ({
    ...provided,
    color: '#808080',
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '120%'
  }),
  menu: provided => ({
    ...provided,
    background: '#ffffff',
    border: 'none',
    borderRadius: '0 0 2rem 2rem',
    margin: '0',
    padding: '1rem',
    zIndex: '2',
    ':hover': {
      border: 'none !important'
    }
  }),
  option: (provided, { value }) => ({
    ...provided,
    color: value === 'new' ? '#ffffff' : '#000000',
    fontSize: '1.4rem',
    lineHeight: '120%',
    cursor: 'pointer',
    textAlign: value === 'new' ? 'center' : 'left',
    borderRadius: value === 'new' ? '2rem' : '1rem',
    background: value === 'new' ? '#1a1a1a' : '#ffffff',
    borderBottom: value === 'allTime' || value === 'all' ? '1px solid #eeeeee' : 'none',
    marginTop: '5px',
    ':hover': {
      color: '#ffffff',
      background: '#1a1a1a'
    }
  })
}
