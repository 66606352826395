import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import { UpdateCompanyStyled } from './UpdateCompanyModal.styled'
import { Button, Checkbox, ErrorDisplay, Input } from 'mmfintech-portal-commons'

import { isValidFunction, tr, useFormValues } from 'mmfintech-commons'
import { useEnotasUpdateCompanyMutation, useGetEnotasMerchantMetadataQuery } from 'mmfintech-backend-api'

type UpdateCompanyModalProps = {
  onClose: () => void
  onSuccess?: () => void
}

export const UpdateCompanyModal = ({ onClose, onSuccess }: UpdateCompanyModalProps) => {
  const [taxationRegimes, setTaxationRegimes] = useState([])

  const [updateCompany, { isLoading: updateCompanyFetching, error: updateCompanyError, reset: updateCompanyReset }] =
    useEnotasUpdateCompanyMutation()

  const { data: metadata } = useGetEnotasMerchantMetadataQuery()

  const {
    isFirstInvoice: usesNfeSequentialAndSeriesAtFirstCompanyUpdate = true,
    prefilledMunicipalRegistrationNumber,
    //usesMunicipalServiceCode,
    //usesItemListService,
    usesDescriptionService,
    usesSpecialTaxationRegime
  } = metadata || {}

  const companyValues = useFormValues({
    issRate: { validation: 'float', value: 0 },
    // lc116: {},
    municipalRegistrationNumber: { required: true, validation: 'numeric' },
    nationalSimple: { value: true },
    nfeSeries: {},
    sequentialNFe: { maxLength: 9, validation: 'numeric', value: 0 },
    // serviceCode: {},
    serviceDescription: {},
    // stateRegistrationNumber - keep for future use
    taxationRegime: {}
  })

  const handleSubmitClick = async () => {
    if (companyValues.areValid()) {
      try {
        await updateCompany(companyValues.prepare()).unwrap()
        onClose()
        isValidFunction(onSuccess) && onSuccess()
      } catch (error) {
        return error
      }
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild()

    return () => {
      // companyValues.reset()
      updateCompanyReset()
    }
  }, [])

  useEffect(() => {
    if (metadata) {
      //companyValues.setRequired('serviceCode', usesMunicipalServiceCode)
      companyValues.setRequired('taxationRegime', usesSpecialTaxationRegime)
      companyValues.setRequired('serviceDescription', usesDescriptionService)
      //companyValues.setRequired('lc116', usesItemListService)
      companyValues.setRequired('sequentialNFe', usesNfeSequentialAndSeriesAtFirstCompanyUpdate)
      companyValues.setRequired('nfeSeries', usesNfeSequentialAndSeriesAtFirstCompanyUpdate)

      const {
        specialTaxationRegimes,
        //prefilledLaw116,
        prefilledMunicipalRegistrationNumber,
        //prefilledMunicipalServiceCode,
        prefilledNfeSeries,
        prefilledServiceDescription,
        prefilledTaxationRegime,
        prefilledNationalSimple,
        prefilledIssRate
      } = metadata || {}

      setTaxationRegimes(specialTaxationRegimes?.map(item => ({ label: item.name, value: item.code })))

      companyValues.initialize({
        issRate: prefilledIssRate,
        municipalRegistrationNumber: prefilledMunicipalRegistrationNumber,
        serviceDescription: prefilledServiceDescription,
        taxationRegime: prefilledTaxationRegime || '0',
        sequentialNFe: prefilledNfeSeries,
        nationalSimple: prefilledNationalSimple
      } as any)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata])

  return (
    <UpdateCompanyStyled className='w-50' data-test='enotas-company-data-modal'>
      <ReactTooltip id='input-info' place='top' type='dark' effect='solid' />

      <div className='title'>{tr('FRONTEND.ENOTAS.INVOICE.CLIENT_UPDATE_TITLE', 'Update company data')}</div>

      <div className='form-container'>
        <Input
          type={prefilledMunicipalRegistrationNumber?.length ? 'static' : 'text'}
          data-test='municipal-registration'
          label={tr('FRONTEND.ENOTAS.INVOICE.MUNICIPAL_NUMBER_LABEL', 'Municipal registration number')}
          {...companyValues.registerInput('municipalRegistrationNumber')}
        />

        {usesDescriptionService && (
          <Input
            type='text'
            data-test='service-description'
            label={tr('FRONTEND.ENOTAS.INVOICE.SERVICE_DESCRIPTION_LABEL', 'Service description')}
            {...companyValues.registerInput('serviceDescription')}
          />
        )}

        {usesSpecialTaxationRegime && (
          <Input
            type='select'
            data-test='taxation-regime'
            options={taxationRegimes}
            label={tr('FRONTEND.ENOTAS.INVOICE.TAXATION_REGIME_LABEL', 'Taxation regime')}
            {...companyValues.registerInput('taxationRegime')}
          />
        )}

        <Input
          type='number'
          data-test='iss-rate'
          label={tr('FRONTEND.ENOTAS.INVOICE.ISS_RATE_LABEL', 'ISS rate')}
          placeholder={tr('FRONTEND.ENOTAS.INVOICE.RATE_PLACEHOLDER', '0%')}
          {...companyValues.registerInput('issRate')}
        />

        <Input
          type='text'
          data-for='input-info'
          data-tip={tr('FRONTEND.ENOTAS.INVOICE.SEQUENTIAL_NFE_TOOLTIP', 'Attention: this is the last NF number + 1')}
          data-test='sequential-nfe'
          label={tr('FRONTEND.ENOTAS.INVOICE.SEQUENTIAL_NFE_LABEL', 'NF Serial Number')}
          {...companyValues.registerInput('sequentialNFe')}
        />

        <Input
          type='text'
          data-test='nfe-series'
          label={tr('FRONTEND.ENOTAS.INVOICE.NFE_SERIES_LABEL', 'NF series')}
          {...companyValues.registerInput('nfeSeries')}
        />

        <div className='mb-2 ml-2' style={{ width: '100%' }}>
          <Checkbox
            label={tr('FRONTEND.ENOTAS.INVOICE.NATIONAL_SIMPLE', 'National simple')}
            {...companyValues.registerCheckbox('nationalSimple')}
          />
        </div>
      </div>

      <ErrorDisplay error={updateCompanyError} />

      <div className='buttons'>
        <Button
          type='button'
          color='primary'
          text={tr('FRONTEND.BUTTONS.SUBMIT', 'Submit')}
          onClick={handleSubmitClick}
          loading={updateCompanyFetching}
          data-test='button-company-update-submit'
          className='mt-2'
        />
      </div>
    </UpdateCompanyStyled>
  )
}
