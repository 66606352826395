import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { enableMapSet, enableES5 } from 'immer'

import './i18n'

import 'react-phone-input-2/lib/style.css'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'

import App from './App'
import { store } from './store'
import { configuration } from 'mmfintech-backend-api'

import { Analytics } from 'mmfintech-commons'

import { pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

enableMapSet()
enableES5()

if (configuration.isProduction()) {
  // @ts-ignore
  window.__kdt = window.__kdt || []
  // @ts-ignore
  window.__kdt.push({ public_key: 'P22843BC00B' })

  const d = document

  const kdt = d.createElement('script')
  kdt.id = 'kdtjs'
  kdt.type = 'text/javascript'
  kdt.async = true
  kdt.src = 'https://i.k-analytix.com/k.js'
  const b = d.getElementsByTagName('body')[0]
  b.parentNode.insertBefore(kdt, b)

  const s = d.createElement('script')
  s.type = 'text/javascript'
  s.id = 'ze-snippet'
  s.src = 'https://static.zdassets.com/ekr/snippet.js?key=f9bb3b4d-5858-44ab-b8bd-2c1b48cb43c6'
  d.getElementsByTagName('body')[0].appendChild(s)
}

ReactDOM.render(
  <React.StrictMode>
    <Analytics enabled={configuration.isProduction()} codes={['GTM-53VRPKQ']} />
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('speedy-app')
)
