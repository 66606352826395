import toast from 'react-hot-toast'

import { ExpenseManagementReport } from 'mmfintech-commons-types'
import { useDeleteReportMutation } from 'mmfintech-backend-api'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { tr } from 'mmfintech-commons'

import { ExpenseModalHeader } from '../../expenses/styles/expenseModal.styled'
import { ReportModalInner, ReportModalWrapper } from './modals.styled'

interface IDeleteReport {
  report: ExpenseManagementReport
  onClose: () => void
}

export const DeleteReportModal = ({ report, onClose }: IDeleteReport) => {
  const [deleteReport, { error, isLoading }] = useDeleteReportMutation()

  const onDeleteReport = async (e: React.MouseEvent) => {
    e.stopPropagation()

    try {
      await deleteReport({ id: report.id }).unwrap()
      toast.success('Report deleted successfully')
      onClose()
    } catch (error) {}
  }

  return (
    <ReportModalWrapper onMouseDown={e => e.stopPropagation()}>
      <ExpenseModalHeader>{tr("FRONTEND.EXPENSE_MODAL.DELETE_REPORT_LABEL" ,"Delete Report")}</ExpenseModalHeader>
      <ReportModalInner>
        <div className='confirmation'>
        {tr("FRONTEND.EXPENSE_MODAL.SECURE_MASSAGE_LABEL" ,"Are you sure you want to delete report")}: <span>{report.name}</span>
        </div>
      </ReportModalInner>
      <ErrorDisplay error={error} />
      <Button
        color='round-primary'
        text={tr('FRONTEND.BUTTONS.DELETE', 'Delete')}
        onClick={onDeleteReport}
        data-test='delete-report-button'
        loading={isLoading}
      />
    </ReportModalWrapper>
  )
}
