import { Redirect } from 'react-router-dom'

import { paths, selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'

import { CustomerRoleEnum } from 'mmfintech-commons-types'

const Outlet = () => {
  const { customerRole } = useAppSelector(selectCurrentUserRole)

  switch (customerRole) {
    case CustomerRoleEnum.APPROVER:
    case CustomerRoleEnum.SUBMITTER:
      return <Redirect to={paths.expenseManagement.expenseManagementDashboard()} />
    default:
      return <Redirect to={paths.dashboard()} />
  }
}

export default Outlet
