import styled from 'styled-components'

export const StyledStatusSwitch = styled.div`
  width: 8.5rem;
  height: 2.8rem;

  border: 1px solid rgba(163, 184, 194, 0.5);
  border-radius: 4.1rem;
  padding: 0 1rem 0 0.5rem;

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  color: #ff3131;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3rem;

  .ball {
    width: 1.8rem;
    height: 1.8rem;
    display: inline-block;
    background: rgba(255, 49, 49, 0.3);
    border-radius: 2.4rem;
  }

  &.active {
    color: #44cc00;
    padding: 0 0.5rem 0 1rem;
    flex-flow: row-reverse nowrap;
    .ball {
      background: #b4eb99;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &.type2 {
    height: 3.5rem;
    width: 9.5rem;

    .ball {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
`
