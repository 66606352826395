import styled from 'styled-components'

export const CurrencyBadgeWrapper = styled.div<{ isSelected: boolean }>`
  background-color: ${props => (props.isSelected ? 'rgba(0, 180, 81,0.3)' : '#fffff')};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.isSelected ? 'rgba(68, 204, 0, 0.5)' : '#E4EAED')};
  border-radius: 42px;
  max-width: 8.5rem;
  width: 100%;
  color: white;
  cursor: pointer;

  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 6px;

  .currency-icon {
    border: 1px solid white;
    border-radius: 100%;
  }

  .currency-name {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
`
