import styled from 'styled-components'

import { tr } from 'mmfintech-commons'

import AddReportIcon from '../../../images/icons/add-select-option.svg?react'

export const SelectCreateLabel = ({ text }: { text: string }) => {
  return (
    <StyledMenuListFooter>
      <MenuListFooterNewValue>
        <AddReportIcon />
        {text}
      </MenuListFooterNewValue>
      <MenuListFooterDescription>{tr('FRONTEND.FOOTER.DESCRIPTION_CREATE', 'Create')}</MenuListFooterDescription>
    </StyledMenuListFooter>
  )
}
const StyledMenuListFooter = styled.div`
  display: flex;
  min-height: 3.3rem;

  align-items: center;
  justify-content: space-between;

  color: #fff;
  background-color: #000;

  font-size: 1.6rem;
  font-weight: 500;

  padding: 0.5rem;
  border-radius: 3.4rem;
  margin-top: 1rem;

  cursor: pointer;
`

const MenuListFooterNewValue = styled.div`
  display: flex;
  gap: 1rem;
`
const MenuListFooterDescription = styled.div`
  color: #afafaf;
  font-size: 1.3rem;
  font-weight: 400;
  margin-right: 1rem;
`
