import styled from 'styled-components'

const CounterpartyModalWrapper = styled.div`
  min-width: 56rem;
  background: #1a1a1a;
  padding: 5rem 7rem;

  @media (max-width: 767px) {
    padding: 6rem 3rem;
    min-width: auto;
  }

  form {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    gap: 6rem;

    .input-wrapper {
      margin: 0;
    }
    button {
      align-self: center;
      max-width: 14.2rem;
    }
  }
`
export { CounterpartyModalWrapper }
