import { ReactNode } from 'react'

import { Button } from 'mmfintech-portal-commons'
import { Centered, OnboardingBannerContent, OnboardingBannerWrapper } from './OnboardingBanner.styled'

import { isValidString } from 'mmfintech-commons'

import WarningTriangle from '../../images/icons/warning-triangle.svg?react'
import ArrowRightWhiteLong from '../../images/icons/arrow-right-white-long.svg?react'

type BannerProps = {
  button?: boolean
  header?: string
  content?: ReactNode
  buttonCaption?: string
  handleClick?: () => void
  className?: string
}

export const Banner = ({ button, header, content, buttonCaption, handleClick, className }: BannerProps) => {
  const clickable = typeof handleClick == 'function'

  const handleBannerClick = () => {
    !button && clickable && handleClick()
  }

  return (
    <OnboardingBannerWrapper button={button} onClick={handleBannerClick} className={className}>
      <Centered className='left'>
        <WarningTriangle />
      </Centered>

      <OnboardingBannerContent>
        {isValidString(header) && <div className='header'>{header}</div>}
        {content}
      </OnboardingBannerContent>

      {clickable && (
        <Centered className='right'>
          {button ? (
            <Button
              type='button'
              color='alternative'
              onClick={handleClick}
              data-test='verify-button'
              text={buttonCaption}
            />
          ) : (
            <ArrowRightWhiteLong />
          )}
        </Centered>
      )}
    </OnboardingBannerWrapper>
  )
}
