import styled from 'styled-components'
import { ExpenseModalContainer } from '../../expenses/styles/expenseModal.styled'

const ReportModalWrapper = styled(ExpenseModalContainer)`
  .input-wrapper {
    margin-bottom: 0;
  }
  .button-round-primary {
    margin-top: 3rem;
    min-width: 20rem;
    align-self: center;
  }
`

const ReportModalInner = styled.div`
  .confirmation {
    color: #afafaf;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6rem;
    display: flex;
    margin-top: 12px;

    span {
      color: #fff;
      margin-left: 0.5rem;
    }
  }
`

export { ReportModalWrapper, ReportModalInner }
