import { CustomMenuIconWrapper } from './CustomUserMenuIcon.styled'

import MenuArrow from '../../images/icons/user-menu-arrow.svg?react'
import MenuIcon from '../../images/icons/user-menu-icon.svg?react'

export const CustomUserMenuIcon = ({ merchantName, onClick }) => (
  <CustomMenuIconWrapper onClick={onClick}>
    <MenuIcon />
    <span>{merchantName}</span>
    <MenuArrow />
  </CustomMenuIconWrapper>
)
