import styled from 'styled-components'

type ProgressBarWithInfoProps = {
  monthlyVolume: number
  percentage: number
}

export const SpendingLimitBarWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const SpentAmount = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;

  .available-text {
    padding-left: 1rem;
    color: rgba(128, 128, 128, 1);
  }
`

export const ProgressBarInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: white;
  width: 45rem;
  padding: 0 2rem;
  margin-bottom: 1rem;

  div:last-of-type {
    text-align: right;
  }

  .info-text {
    color: #808080;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
  .infinity {
    font-size: 1.6rem;
    font-weight: 700;
  }
`

export const ProgressBarWithInfoWrapper = styled.div<ProgressBarWithInfoProps>`
  height: 40px;
  background: #4f4f4f;
  width: 45rem;
  margin-bottom: 20px;
  border-radius: 61px;

  .progress-bar {
    display: flex;
    align-items: center;
    height: 40px;
    background: ${({ percentage, monthlyVolume }) =>
      monthlyVolume
        ? percentage <= 50
          ? 'linear-gradient(271.13deg, #00B451 0.96%, #00BF36 128.18%)'
          : percentage <= 80
            ? 'linear-gradient(271.13deg, #FC9505 0.96%, #E9B632 128.18%)'
            : 'linear-gradient(271.13deg, #B91C30 0.96%, #E11E38 128.18%)'
        : 'linear-gradient(271.13deg, #006CD0 0.96%, #00B4BF 128.18%)'};
    border-radius: 61px;
    width: ${({ percentage }) => (percentage < 9 ? 9 : percentage)}%;
    color: white;
    justify-content: ${({ monthlyVolume }) => (monthlyVolume ? 'flex-end' : 'flex-start')};
    padding: 0 5px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      height: 30px;
      width: 30px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 9px;
      position: relative;
    }
  }
`
