import { ReactNode } from 'react'

import { NoReportsWrapper } from './NoReportsWrapper'

import NoReportImage from '../../images/expenses/no-reports-icon.svg?react'

type TNoReportsComponentProps = {
  title?: string
  children?: ReactNode
}

export const NoReportsComponent = ({ title, children }: TNoReportsComponentProps) => {
  return (
    <tbody>
      <tr>
        <td colSpan={10}>
          <NoReportsWrapper>
            <>
              <NoReportImage />
              {title && <p>{title}</p>}
              {children && { children }}
            </>
          </NoReportsWrapper>
        </td>
      </tr>
    </tbody>
  )
}
