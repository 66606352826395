import styled from 'styled-components'

import cornerImage from '../../images/speedy-invoice-corner.png'

export const InvoiceWrapper = styled.div`
  grid-area: main-content;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  padding: 0 2rem;
  margin: 4rem 0 0;

  @media (min-width: 1200px) {
    margin: 5.5rem 0 0;
  }
`

export const InvoiceMainContainer = styled.div`
  width: 100%;
  max-width: 115.2rem;

  .invoice-edit-title {
    margin-bottom: 2rem;
  }

  @media (min-width: 1200px) {
    .invoice-edit-title {
      margin-bottom: 4rem;
    }
  }
`

export const InvoiceContainer = styled.div`
  background: #1a1a1a;
  border-radius: 2rem;
  position: relative;

  width: 100%;
  margin-bottom: 4rem;
  padding: 5rem 3rem 2rem;

  @media (min-width: 1200px) {
    padding: 5rem 7rem;
  }

  .invoice-title {
    color: #ffffff;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

    grid-row: 2;

    @media (min-width: 1200px) {
      grid-row: auto;
      margin-top: 2rem;
    }
  }

  .merchant-info,
  .thank-you {
    color: #afafaf;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    @media (min-width: 1200px) {
      margin-left: 10rem;
    }

    .merchant-name {
      color: #ffffff;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
  .merchant-info {
    margin-bottom: 2rem;
    @media (min-width: 1200px) {
      margin-bottom: 0;
    }
  }
  .thank-you {
    margin-bottom: 3rem;

    @media (min-width: 1200px) {
      margin-bottom: 5rem;
    }
  }

  .dates-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    row-gap: 1rem;
    margin-bottom: 3rem;

    @media (min-width: 480px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      row-gap: 0;
    }
  }

  [data-test='select-input-payerId'] {
    grid-column: 1;
  }

  .invoice-items-table {
    width: 100%;
    display: block;
    border-collapse: collapse;
    margin: 7rem 0 4rem;

    color: #808080;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;

    thead {
      tr {
        display: none;
        th {
          font-weight: normal;
          text-align: left;
          padding-bottom: 1rem;
        }
      }
    }

    tbody {
      display: block;
      tr {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        color: #ffffff;
        background: #000000;
        padding: 0 2rem 2rem;
        margin-bottom: 2rem;

        &.invoice-error-row {
          padding: 0;
          margin-bottom: 0;
        }

        td {
          padding: 0;

          &.delete-icon {
            padding-left: 2rem;
            cursor: pointer;

            a:hover {
              svg * {
                stroke: #ffffff;
              }
            }
          }

          /*input {
            outline: none;
            border-radius: 0;

            :-webkit-autofill,
            :-webkit-autofill:hover,
            :-webkit-autofill:focus,
            :-webkit-autofill:active {
              box-shadow: 0 0 0 3rem #1a1a1a inset !important;
              -webkit-box-shadow: 0 0 0 3rem #1a1a1a inset !important;
              border: none !important;
              outline: none !important;
            }
          }*/
        }

        .row-total-amount {
          white-space: nowrap;
        }

        @media (max-width: 899px) {
          &.invoice-error-row {
            margin-top: -2rem;
            margin-bottom: 2rem;
            padding-bottom: 1rem;
            .err-msg-wrapper {
              color: #ff0431;
              font-size: 1.4rem;
              width: auto;
            }
          }

          td {
            width: 100%;

            &.delete-icon {
              padding-top: 2rem;
              text-align: right;
              a {
                width: 4.8rem;
                height: 4.8rem;
                border-radius: 3.2rem;
                display: inline-grid;
                place-items: center;
                cursor: pointer;
                background: #ff0431;

                svg > *,
                :hover svg > * {
                  stroke: #ffffff;
                }
              }
            }

            .row-number-cell,
            .row-total-amount {
              width: 100%;
              height: 4rem;
              display: flex;
              align-items: center;
              padding: 0 2rem;
              color: #ffffff;
              border: 1px solid #808080;
              border-radius: 3.6rem;
            }
            input {
              width: 100%;
              height: 4rem;
              padding: 0 2rem;

              border: 1px solid #808080;
              border-radius: 3.6rem;
              background: transparent;

              :-webkit-autofill,
              :-webkit-autofill:hover,
              :-webkit-autofill:focus,
              :-webkit-autofill:active {
                box-shadow: 0 0 0 3rem #1a1a1a inset !important;
                -webkit-box-shadow: 0 0 0 3rem #1a1a1a inset !important;
                border: 1px solid #808080 !important;
                outline: none !important;
              }
            }
          }
        }

        &.invoice-error-row {
          .err-msg-wrapper {
            margin: 0 0 0 3rem;
          }
        }
      }
    }

    .p-column-title {
      display: block;
      color: #808080;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      margin: 2rem 0 0.5rem 2rem;
    }

    @media (min-width: 900px) {
      display: table;

      .p-column-title {
        display: none;
      }

      thead {
        tr {
          display: table-row;
        }
      }

      tbody {
        display: table-row-group;
        tr {
          display: table-row;
          color: #ffffff;
          background: transparent;
          border-bottom: 1px solid #282828;
          padding: 0;

          td {
            padding: 0;

            input {
              padding-left: 0;
              border-color: transparent;
              background-color: transparent;
            }
          }

          &.invoice-error-row {
            .err-msg-wrapper {
              margin: 0 0 0 3rem;
            }
          }
        }
      }
    }
  }

  button.add-line-button {
    width: fit-content;
    color: #000000;

    svg {
      margin-left: -0.5rem;
      margin-right: 0.5rem;
      height: 2rem;
      width: 2rem;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1.5rem;

    &.final {
      padding-bottom: 10rem;
      border-bottom: 1px solid #282828;
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 6rem;
      row-gap: 0;
    }

    .buttons {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;

      .button {
        color: #000000;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: 90%;

        margin-right: 2rem;
        :last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .label {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    color: #808080;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .date-label {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-left: 2rem;

    color: #808080;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;
  }

  .total-container {
    color: #afafaf;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-end;

    .invoice-totals {
      width: 100%;

      @media (min-width: 900px) {
        max-width: 45rem;
      }

      .row {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 2rem;

        &.bordered {
          border-bottom: 1px solid #282828;
        }
        &.total {
          color: #ffffff;
          font-size: 1.8rem;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
        }
      }
    }

    .value {
      width: 20rem;
      text-align: right;
    }
  }
`

export const InvoiceFooter = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;

  .button {
    :first-of-type {
      margin-top: 1rem;
    }

    .button-text-and-icon {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  @media (min-width: 480px) {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;

    .button {
      width: auto;
      min-width: 13rem;

      :first-of-type {
        margin-top: 0;
        margin-right: 3rem;
      }
    }
  }
`

export const InvoiceCorner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 81px;
  height: 81px;
  //prettier-ignore
  background: #202020 url("${cornerImage}") center center no-repeat;

  &.popup {
    background-color: transparent;
  }
`

export const InvoiceDateWrapper = styled.div`
  cursor: pointer;

  width: 100%;
  height: 4rem;
  padding: 0 1.5rem;

  background: #000000;
  border-radius: 4.4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #afafaf;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  & > span {
    display: flex;
    align-items: center;
  }

  img,
  svg {
    margin-right: 1rem;
  }
  svg * {
    stroke: #4f4f4f;
  }
`
