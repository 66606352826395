import styled from 'styled-components'

export const TableCustomizeWrapper = styled.div`
  width: 100vw;
  max-width: 45rem;
  background: #1a1a1a;
  padding: 4rem 5rem 5rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  .button {
    width: 13rem;
    height: 3.4rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 120%;
    color: #ffffff;
  }
`

export const TableCustomizeTitle = styled.div`
  color: #ffffff;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 2rem;
`
