import styled from 'styled-components'

export const VerticallyCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;

  .approve-button {
    background: #00b451;

    &:hover {
      background: #009944;
    }
  }

  h1 {
    font-size: 36px;
  }
`
export const HeaderContainer = styled.div`
  position: relative;
  box-sizing: border-box;

  &.padding-top-SA {
    margin-top: 58px;
  }

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  margin-bottom: 2rem;

  @media (min-width: 960px) {
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .search {
    @media (max-width: 960px) {
      display: block;
      margin-bottom: 2rem;
    }
  }

  .label {
    font-size: 36px;
    font-weight: 500;
    color: #fff;
    padding: 0 2rem 1rem 0;

    @media (max-width: 960px) {
      padding: 0;
    }
  }

  .total-elements {
    position: absolute;
    top: 90px;
    left: 0;
    color: #afafaf;
    font-size: 1.6rem;

    @media (max-width: 960px) {
      display: none;
    }
  }

  .left-wrapper {
    display: flex;
    flex-direction: row;

    .restore {
      background-color: green;
      background: #00b451;

      &:hover {
        background: #009944;
      }
    }

    @media (max-width: 960px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      margin-bottom: 2rem;
    }
  }

  .right-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .action-report-button {
      width: 140px;
    }

    @media (max-width: 960px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .right-wrapper-counterparty {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 24px 0 36px 0;

    @media (max-width: 960px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .add-user-button {
    padding: 2rem 4rem 2rem 4rem;
    position: relative;
    font-size: 1.5rem;
    height: 0;

    &:before {
      content: '';
      width: 14px;
      height: 2px;
      background: #000000;
      position: absolute;
      top: 19px;
      left: 20px;

      @media (max-width: 960px) {
        position: absolute;
        top: 24px;
        left: 145px;
      }
    }

    &:after {
      content: '';
      width: 2px;
      height: 14px;
      background: #000000;
      position: absolute;
      top: 13px;
      left: 26px;

      @media (max-width: 960px) {
        position: absolute;
        top: 18px;
        left: 151px;
      }
    }

    @media (max-width: 960px) {
      padding: 2rem 17rem 2rem 17rem;
      margin: 2rem 0;
    }
  }

  h1 {
    margin: 0;
  }
`
