import { useCallback, useMemo, useState } from 'react'
import { TailSpin } from 'react-loader-spinner'
import { Document, Page } from 'react-pdf'

import { Button } from 'mmfintech-portal-commons'

import PageChangeIcon from '../../../images/icons/arrow-small-black.svg?react'
import ArrowDownIcon from '../../../images/icons/arrow-down.svg?react'
import CloseIcon from '../../../images/icons/close.svg?react'
import Default from '../../../images/icons/receipt-default.svg?react'

import { DocumentContainer, DocumentWrapper, PageFooter, PageWrapper } from './styles/PreviewFile.styled'
import { tr } from 'mmfintech-commons'

const VIEWER_WIDTH = 300
const MIN_VIEWER_HEIGHT = 385
const SCALE = 2

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/'
}

export type FilePreview = {
  file: {
    type: string
    fileUrl: string
  }
  isLoading?: boolean
  error?: string
  removeFile?: () => void
  downloadFile?: () => void
  stylingOptions?: {
    width?: number
    height?: number
    scale?: number
    showFooter?: boolean
  }
}

export const PreviewFile: React.FC<FilePreview> = ({ file, removeFile, downloadFile, isLoading, stylingOptions }) => {
  const {
    width = VIEWER_WIDTH,
    height = MIN_VIEWER_HEIGHT,
    scale = width < 100 ? 1 : SCALE,
    showFooter = true
  } = stylingOptions || {}

  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [imageSrc, setImageSrc] = useState('')
  const [isPdfError, setIsPdfError] = useState(false)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
    setIsPdfError(false)
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  function previousPage() {
    if (pageNumber > 1) {
      changePage(-1)
    }
  }

  function nextPage() {
    if (pageNumber < numPages) {
      changePage(1)
    }
  }

  const handleMouseMove = useCallback(event => {
    const wrapper = event.currentTarget
    const rect = wrapper.getBoundingClientRect()
    if (width < 100) {
      return
    }
    const xPercent = ((event.clientX - rect.left) / rect.width) * 100
    const yPercent = ((event.clientY - rect.top) / rect.height) * 100

    wrapper.style.setProperty('--x', `${xPercent}%`)
    wrapper.style.setProperty('--y', `${yPercent}%`)
  }, [])

  useMemo(() => {
    if (file) {
      setNumPages(0)
      setImageSrc(file.fileUrl)
    }
  }, [file])

  if (!isLoading && !file) {
    return <div>{tr('FRONTEND.PREVIEW_FILE.NOT_LOAD_LABEL', 'Could not load the file')}</div>
  }

  return (
    <DocumentContainer $solidBorder>
      <DocumentWrapper $VIEWER_WIDTH={width}>
        {isLoading ? (
          <TailSpin />
        ) : file?.type?.includes('image') ? (
          <PageWrapper $MIN_VIEWER_HEIGHT={height} $SCALE={scale} onMouseMove={handleMouseMove}>
            {imageSrc ? (
              <img
                src={imageSrc}
                width={width}
                style={{ objectFit: 'contain' }}
                onError={() => setImageSrc('')}
                loading='lazy'
                alt=''
              />
            ) : (
              <Default style={{ width: width, height: '100%' }} />
            )}
          </PageWrapper>
        ) : (
          <>
            {!isPdfError ? (
              <Document
                file={file.fileUrl}
                options={options}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={() => setIsPdfError(true)}>
                <PageWrapper $MIN_VIEWER_HEIGHT={height} $SCALE={scale} onMouseMove={handleMouseMove}>
                  <Page pageNumber={pageNumber} width={width} _className='pdf-page' devicePixelRatio={scale} />
                </PageWrapper>
              </Document>
            ) : (
              <PageWrapper $MIN_VIEWER_HEIGHT={height} $SCALE={scale} onMouseMove={handleMouseMove}>
                <Default style={{ width: width, height: '100%' }} />
              </PageWrapper>
            )}
          </>
        )}
      </DocumentWrapper>
      {showFooter ? (
        <PageFooter>
          <div className='buttons-container'>
            {typeof removeFile === 'function' ? (
              <Button
                text={tr('FRONTEND.PREVIEW_FILE.REMOVE_LABEL', 'Remove')}
                onClick={removeFile}
                color='round-secondary'
                icon={<CloseIcon height={15} width={15} />}
                iconPosition='left'
                className='action-button'
              />
            ) : null}
            {typeof downloadFile === 'function' ? (
              <Button
                text={tr('FRONTEND.PREVIEW_FILE.DOWNLOAD_LABEL', 'Download')}
                onClick={downloadFile}
                color='round-secondary'
                className='action-button'
                icon={<ArrowDownIcon />}
                iconPosition='left'
              />
            ) : null}
          </div>
          {numPages > 0 && (
            <div className='controls'>
              <PageChangeIcon onClick={previousPage} />
              <div>
                {pageNumber} of {numPages}
              </div>
              <PageChangeIcon className='forward-icon' onClick={nextPage} />
            </div>
          )}
        </PageFooter>
      ) : null}
    </DocumentContainer>
  )
}
