import styled from 'styled-components'

import InfoImage from '../../images/icons/input-info.svg'

export const CustomInputWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  width: 100%;
  min-height: 6rem;
  position: relative;

  :first-of-type {
    border-radius: 0.7rem 0.7rem 0 0;
  }

  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee;

  color: #000000;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    box-shadow: 0 0 3rem 3rem #ffffff inset !important;
    -webkit-box-shadow: 0 0 0 3rem #ffffff inset !important;
    -webkit-text-fill-color: #000000 !important;
    caret-color: #000000 !important;
    outline: none !important;
    border: none;
  }

  label {
    color: #afafaf;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .input-inner-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem;

    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    .left-icons {
      margin-right: 1rem;
      display: grid;
      width: 2rem;
    }

    &.error {
      .left-icons {
        svg {
          &.stroke > * {
            stroke: #ff0431;
          }
          &.fill > * {
            fill: #ff0431;
          }
        }
      }
    }

    .input-container {
      width: 100%;
      height: 5rem;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
    }

    .right-icons {
      margin-left: 1rem;
      display: inline-flex;
      flex-flow: row nowrap;
      align-items: center;

      .spacer {
        width: 1rem;
      }

      .info {
        width: 17px;
        height: 17px;
        cursor: pointer;
        //prettier-ignore
        background: url("${InfoImage}") center center no-repeat;
      }

      img,
      svg {
        cursor: pointer;
        :focus {
          outline: none;
        }
      }
    }
  }

  .date-input {
    width: 100%;
    height: 5rem;
    outline: none;
    border-radius: 4px;
    padding-left: 1.6rem;
    padding-right: 1.6rem;

    &::-webkit-input-placeholder {
      color: #afafaf;
      font-family: 'Helvetica Now Display', sans-serif;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
    &:-ms-input-placeholder {
      color: #afafaf;
      font-family: 'Helvetica Now Display', sans-serif;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
    &::placeholder {
      color: #afafaf;
      font-family: 'Helvetica Now Display', sans-serif;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }

  .static-input-wrapper {
    border: none;
    background-color: transparent;

    color: #000000;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 5rem;
    padding-bottom: 0.5rem;
  }
`

export const StyledDatePickerWrapper = styled.div`
  width: 100%;

  .date-input {
    background: transparent;
    border: none;
    outline: none;

    font-size: 1.8rem;
    line-height: 140%;
    font-style: normal;
    font-weight: 400;
    ::placeholder {
      font-size: 1.8rem;
      line-height: 140%;
    }
  }
`

export const StyledPhoneInputWrapper = styled.div`
  .react-tel-input {
    background-color: transparent;

    .form-control {
      width: 100%;
      font-size: 1.8rem;
      border-color: transparent;
      background-color: transparent;
      padding-left: 4rem !important;

      ${'' /* placeholder styling */}
      &::-webkit-input-placeholder {
        color: #afafaf;
        font-family: 'Helvetica Now Display', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
      &:-ms-input-placeholder {
        color: #afafaf;
        font-family: 'Helvetica Now Display', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
      &::placeholder {
        color: #afafaf;
        font-family: 'Helvetica Now Display', sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }
    .flag-dropdown {
      border-color: transparent;
      background-color: transparent;
      width: 4rem !important;
    }
    .selected-flag {
      padding: 0;
      margin: 0;
      background-color: transparent !important;
    }
    .country.highlight:hover,
    .country:hover {
      background-color: #000000;
      color: #ffffff;
    }
  }

  .react-tel-input .flag-dropdown:hover .selected-flag,
  .react-tel-input .flag-dropdown:focus .selected-flag {
    background: none;
  }

  .country-name {
    display: inline-block !important;
    padding: 0 !important;
    line-height: 1.6rem !important;
  }
`

export const InputTextArea = styled.textarea`
  width: 100%;
  outline: none;
  border-radius: 1rem;

  font-size: 1.8rem;
  font-weight: 400;
  line-height: 140%;

  &::-webkit-input-placeholder {
    color: #afafaf;
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  &:-ms-input-placeholder {
    color: #afafaf;
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  &::placeholder {
    color: #afafaf;
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`

export const InputText = styled.input`
  width: 100%;
  padding: 0;
  border: none;
  outline: none;

  font-size: 1.8rem;
  font-weight: 400;
  line-height: 140%;

  &.required {
    padding-left: 6px;
  }

  ${'' /* remove spin buttons form number input fields */}
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-input-placeholder {
    color: #afafaf;
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  &:-ms-input-placeholder {
    color: #afafaf;
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  &::placeholder {
    color: #afafaf;
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`

export const CustomInputError = styled.span`
  display: block;
  text-align: left;
  margin-bottom: 1rem;
  padding: 0 0 0 1.5rem;

  &.error-message {
    color: #eb4034;
  }

  &.left-icon {
    padding-left: 6.5rem;
    padding-right: 3rem;
  }
  &.phone-input-error {
    padding-left: 7rem;
    padding-right: 3rem;
  }

  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;
`
