import styled from 'styled-components'

export const PaymentCodeViewStyled = styled.div`
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  color: black;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const PaymentViewTitle = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`

export const PaymentViewCode = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;

  font-size: 2rem;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 1px;

  .icon {
    cursor: pointer;
  }
`
