import { useContext } from 'react'

import { toast } from 'react-hot-toast'

import { Button } from 'mmfintech-portal-commons'
import { AccountCreateModal } from '../AccountCreateModal'
import { NoAvailableAccountsWrapper } from './NoAvailableAccounts.styled'

import { GlobalContext, tr } from 'mmfintech-commons'

import NoAccountsIcon from '../../../images/icons/no-accounts-icon.svg?react'

interface NoAvailableAccountsProps {
  currency: string
  reload: () => void
}

export const NoAvailableAccounts = ({ currency, reload }: NoAvailableAccountsProps) => {
  const { modalShow }: any = useContext(GlobalContext)

  const handleAddAccount = () => {
    modalShow({
      options: { closeOnClickOutside: false, size: 'auto', overflow: 'none' },
      content: (
        <AccountCreateModal
          filteredCurrencies={[currency]}
          onSuccess={(currencyCode: string) => {
            reload()
            toast.remove()
            toast.success(
              tr('FRONTEND.ACCOUNTS.CREATE.SUCCESS', '{{CURRENCY}} account successfully added', {
                CURRENCY: currencyCode
              })
            )
          }}
        />
      )
    })
  }

  return (
    <NoAvailableAccountsWrapper>
      <div className='left'>
        <NoAccountsIcon />
        <div>
          {tr('FRONTEND.ACCOUNTS.NO_ACCOUNTS_FOR_CURRENCY.TITLE', 'No available accounts in the currency you chose.')}
        </div>
      </div>
      <Button
        text={tr('FRONTEND.ACCOUNTS.NO_ACCOUNTS_FOR_CURRENCY.BUTTON_ADD', 'Add new account')}
        onClick={handleAddAccount}
        color='secondary'
      />
    </NoAvailableAccountsWrapper>
  )
}
