import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'

import { LanguageMenu } from '../LanguageMenu'
import { UserDropDownMenu } from '../UserDropDownMenu'
import {
  HeaderInnerContainer,
  BrandLogoWrapper,
  HeaderContainer,
  HamburgerIcon,
  HeaderWrapper,
  HamburgerMenu,
  Hamburger,
  HeaderRightContainer
} from './Header.styled'

import settings from '../../settings'
import { useAppDispatch } from '../../hooks'
import { tr, useWindowSize, isEmptyString, isMobileDevice } from 'mmfintech-commons'
import {
  api,
  configuration,
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserRole,
  useAppSelector,
  useAuth,
  useDashboardRedirect,
  useGetMerchantQuery,
  useLazyCardLogoutQuery,
  useLazyLogoutQuery
} from 'mmfintech-backend-api'

import { CustomerRoleEnum, LoginStatusEnum, MerchantAccountTypeEnum } from 'mmfintech-commons-types'

import BrandLogoImg from '../../images/speedy-io-logo.svg?react'

const MAX_MOBILE_WIDTH = 1279

export const Header = () => {
  const [opened, setOpened] = useState(false)

  // noinspection JSCheckFunctionSignatures
  const { path: dashboardPath } = useDashboardRedirect()

  const userStatus = useAuth()
  const customerRole = useAppSelector(selectCurrentUserRole)

  const { data: merchant } = useGetMerchantQuery()
  const { apiEnabled, accountType, capabilities, entityType } = merchant || {}
  const { allowReferrals } = capabilities || {}

  const dispatch = useAppDispatch()
  const [width] = useWindowSize()

  const [logout] = useLazyLogoutQuery()
  const [cardLogout] = useLazyCardLogoutQuery()

  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN
  const isLoggedInAndNotSubmitterOrApprover = () =>
    userStatus === LoginStatusEnum.LOGGED_IN &&
    !(customerRole === CustomerRoleEnum.SUBMITTER || customerRole === CustomerRoleEnum.APPROVER)

  const isAccountMenuVisible = () =>
    accountType /*todo && paymentAccounts?.length > 0*/ && isOwnerOrAdministrator(customerRole)

  const isDevelopersMenuVisible = () => !!apiEnabled && isOwnerOrAdministrator(customerRole)

  const isUsersMenuVisible = () =>
    isOwnerOrAdministrator(customerRole) &&
    ((merchant?.legalEntity === 'ST_POLAND' && accountType !== MerchantAccountTypeEnum.PROSPECT) ||
      merchant?.legalEntity !== 'ST_POLAND')

  const isReferralVisible = () =>
    allowReferrals &&
    isOwnerOrAdministrator(customerRole) &&
    !(merchant?.legalEntity === 'ST_POLAND') &&
    !(accountType === MerchantAccountTypeEnum.PROSPECT && isEmptyString(entityType))

  const isHamburgerVisible = () => width < MAX_MOBILE_WIDTH

  const hideMenu = () => opened && setOpened(false)

  const handleLogout = async () => {
    await logout(null).unwrap()
    dispatch(api.util.resetApiState())
  }

  const handleCardLogout = async () => {
    try {
      await cardLogout(null).unwrap()
    } catch (_err) {
      // nothing
    }
  }

  const handleLogoutClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    hideMenu()
    void handleCardLogout()
    void handleLogout()
    // history.push(paths.login())

    return false
  }

  const BrandLogoImage = () => <BrandLogoImg style={{ maxHeight: '32px' }} />

  const BrandLogo = () => {
    return (
      <BrandLogoWrapper>
        {isMobileDevice() ? (
          <BrandLogoImage />
        ) : isLoggedIn() ? (
          <Link to={dashboardPath}>
            <BrandLogoImage />
          </Link>
        ) : (
          <a href={settings.landingPageUrl}>
            <BrandLogoImage />
          </a>
        )}
      </BrandLogoWrapper>
    )
  }

  const MainMenuItems = () => (
    <>
      <NavLink activeClassName='active-tab' to={dashboardPath} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.OVERVIEW', 'Overview')}
      </NavLink>
      {isAccountMenuVisible() && (
        <NavLink activeClassName='active-tab' to={paths.banking.accounts.list()} onClick={hideMenu}>
          {tr('FRONTEND.HEADER.ACCOUNTS', 'Accounts')}
        </NavLink>
      )}
      {!configuration.isProduction() && (
        <NavLink activeClassName='active-tab' to={paths.card()} onClick={hideMenu}>
          {tr('FRONTEND.HEADER.CARDS', 'Cards')}
        </NavLink>
      )}
      <NavLink activeClassName='active-tab' to={paths.banking.transactions.list()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.TRANSACTIONS', 'Transactions')}
      </NavLink>
      <NavLink
        activeClassName='active-tab'
        to={paths.expenseManagement.expenseManagementDashboard()}
        onClick={hideMenu}>
        {tr('FRONTEND.HEADER.EXPENSES', 'Expenses')}
      </NavLink>

      <NavLink activeClassName='active-tab' to={paths.invoices.list()} onClick={hideMenu}>
        {tr('FRONTEND.HEADER.INVOICES', 'Invoices')}
      </NavLink>
      {isReferralVisible() && (
        <NavLink activeClassName='active-tab' to={paths.referralProgram()} onClick={hideMenu}>
          {tr('FRONTEND.HEADER.REFERRAL', 'Invite & Earn')}
        </NavLink>
      )}
      {isDevelopersMenuVisible() && (
        <NavLink activeClassName='active-tab' to={paths.developer()} onClick={hideMenu}>
          {tr('FRONTEND.HEADER.DEVELOPERS', 'Developers')}
        </NavLink>
      )}
    </>
  )

  const UserMenuItems = ({ onClick }) => (
    <>
      <NavLink to={paths.settings()} onClick={onClick}>
        {tr('FRONTEND.HEADER.SETTINGS', 'Settings')}
      </NavLink>
      {isUsersMenuVisible() ? (
        <NavLink to={paths.userManagement()} onClick={onClick}>
          {tr('FRONTEND.HEADER.USERS', 'Users')}
        </NavLink>
      ) : null}
      <a href={settings.customerServiceUrl} rel='noopener noreferrer' target='_blank' onClick={onClick}>
        {tr('FRONTEND.HEADER.CUSTOMER_SERVICE', 'Customer Service')}
      </a>
      {isOwnerOrAdministrator(customerRole) && (
        <NavLink to={paths.yourForest()} onClick={onClick}>
          {tr('FRONTEND.HEADER.FOREST', 'Your Forest')}
        </NavLink>
      )}
    </>
  )

  useEffect(() => {
    width > MAX_MOBILE_WIDTH && hideMenu()
    // eslint-disable-next-line
  }, [width])

  useEffect(() => {
    const body = document.getElementsByTagName('body').item(0)
    if (body) {
      if (opened) {
        body.classList.add('menu-opened')
      } else {
        body.classList.remove('menu-opened')
      }
    }
    // eslint-disable-next-line
  }, [opened])

  return (
    <HeaderWrapper>
      {opened && <div className='overlay' onClick={hideMenu} />}

      <HeaderContainer maxMobileWidth={MAX_MOBILE_WIDTH}>
        <BrandLogo />

        {!isHamburgerVisible() && isLoggedInAndNotSubmitterOrApprover() && (
          <HeaderInnerContainer>
            <MainMenuItems />
          </HeaderInnerContainer>
        )}

        {isHamburgerVisible() ? (
          <HeaderInnerContainer>
            <Hamburger>
              <HamburgerIcon onClick={() => setOpened(prevState => !prevState)}>
                <span className='menu-icon' />
              </HamburgerIcon>
              <HamburgerMenu open={opened} maxMobileWidth={MAX_MOBILE_WIDTH}>
                <LanguageMenu hamburger={true} />
                {isLoggedInAndNotSubmitterOrApprover() && <MainMenuItems />}
                {isLoggedIn() && <UserMenuItems onClick={hideMenu} />}
                {isLoggedIn() && (
                  <NavLink to='#' onClick={handleLogoutClick}>
                    {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
                  </NavLink>
                )}
                {!isLoggedIn() && (
                  <NavLink to={paths.login()} onClick={hideMenu}>
                    {tr('FRONTEND.HEADER.LOGIN', 'Log in')}
                  </NavLink>
                )}
                {!isLoggedIn() && (
                  <NavLink to={paths.signUp()} onClick={hideMenu}>
                    {tr('FRONTEND.HEADER.SIGNUP', 'Sign Up')}
                  </NavLink>
                )}
              </HamburgerMenu>
            </Hamburger>
          </HeaderInnerContainer>
        ) : (
          <HeaderRightContainer>
            <LanguageMenu hamburger={false} />
            {isLoggedIn() && (
              <UserDropDownMenu
                customUserMenuHeader={true}
                customLogoutButton={false}
                customMenuItem={false}
                UserMenuItems={UserMenuItems}
              />
            )}
          </HeaderRightContainer>
        )}
      </HeaderContainer>
    </HeaderWrapper>
  )
}
