import styled from 'styled-components'

export const TransactionWrapper = styled.div`
  grid-row: 2 / 4;
  grid-column: left-column / right-column;

  display: flex;
  justify-content: center;

  padding: 0 0.5rem;
`

export const TransactionTitle = styled.div`
  color: #ffffff;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  text-align: center;
  margin-bottom: 2rem;

  @media (min-width: 900px) {
    margin-bottom: 4rem;
  }
`

export const TransactionContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  width: 100%;
  padding: 2rem 2rem 4.5rem;

  @media (min-width: 900px) {
    padding-top: 4rem;
  }
  @media (min-width: 1175px) {
    padding-top: 6rem;
  }

  &.centered {
    justify-content: center;
  }
`

export const TransactionContent = styled.div`
  width: 100%;
  max-width: 45rem;
  display: flex;
  flex-flow: column nowrap;

  .caption {
    color: #ffffff;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 1.5rem;
  }

  .static-input-wrapper {
    color: #808080;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 90%;
  }

  .bank-transfer-tooltip,
  .crypto-tooltip {
    max-width: 35rem;
  }
`

export const TransactionButtons = styled.div`
  width: 100%;
  padding: 3rem 3rem 0;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  &.no-padding {
    padding: 3rem 0 0;
  }

  .button {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 90%;

    margin-bottom: 0.8rem;
    :last-of-type {
      margin-bottom: 0;
    }
  }
`
