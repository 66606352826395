import { FC } from 'react'

import { Pagination } from 'mmfintech-portal-commons'

import { ReportsTable } from '../ReportsTable'

import { IReportTableProps } from './@types'

export const AllReports: FC<IReportTableProps> = ({ data, pagination, isApproverTable }) => (
  <>
    <ReportsTable data={data} isApproverTable={isApproverTable} reloadPagination={() => pagination.reload()} />
    <Pagination {...pagination.register()} />
  </>
)
