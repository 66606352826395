import { useHistory } from 'react-router-dom'

import { Button } from 'mmfintech-portal-commons'
import { CityNotSupportedStyled } from './CityNotSupportedModal.styled'

import { tr } from 'mmfintech-commons'
import { paths } from 'mmfintech-backend-api'

type CityNotSupportedModalProps = {
  onClose: () => void
}

export const CityNotSupportedModal = ({ onClose }: CityNotSupportedModalProps) => {
  const history = useHistory()

  const handleSpeedyPayClick = () => {
    history.push(paths.invoices.create())
    onClose()
  }

  return (
    <CityNotSupportedStyled data-test='enotas-city-not-supported-modal'>
      <div className='title'>{tr('FRONTEND.ENOTAS.NOT_SUPPORTED.TITLE', 'Not available')}</div>

      <div>
        {tr(
          'FRONTEND.ENOTAS.NOT_SUPPORTED.MESSAGE',
          "Sorry, the NF for your city is not yet available. We'll let you know when it is! Until then, how about using our Speedy Pay?"
        )}
      </div>

      <div className='buttons'>
        <Button
          type='button'
          color='round-primary'
          text={tr('FRONTEND.ENOTAS.NOT_SUPPORTED.SPEEDYPAY_BUTTON', 'Speedy Pay')}
          onClick={handleSpeedyPayClick}
          iconPosition='right'
          icon='right-arrow'
          data-test='button-speedy-pay'
        />
      </div>
    </CityNotSupportedStyled>
  )
}
