import { FC, FormEvent, useEffect } from 'react'

import toast from 'react-hot-toast'

import { isValidObject, tr, useFormValues } from 'mmfintech-commons'
import { Button, Input } from 'mmfintech-portal-commons'
import { useCreateCounterpartyMutation, useUpdateCounterpartyMutation } from 'mmfintech-backend-api'

import { ExpenseModalHeader } from '../../reports/expenses/styles/expenseModal.styled'
import { CounterpartyModalWrapper } from '../styles/coutnerpartyCreateModal'
import { Counterparty } from 'mmfintech-commons-types'

interface CounterpartyCreateModalProps {
  onClose: () => void
  counterparty?: Counterparty
}

const CounterpartyCreateModal: FC<CounterpartyCreateModalProps> = ({ onClose, counterparty }) => {
  const { registerInput, areValid, prepare } = useFormValues({
    name: { required: true, validation: 'safe-string', value: counterparty?.name }
  })
  const [createCounterpartyRequest, { isLoading: loadingCreate, reset: resetCreate }] = useCreateCounterpartyMutation()
  const [updateCounterpartyRequest, { isLoading: loadingUpdate, reset: resetUpdate }] = useUpdateCounterpartyMutation()

  const isLoading = loadingCreate || loadingUpdate

  const handleReset = () => {
    resetCreate()
    resetUpdate()
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (areValid()) {
      try {
        counterparty
          ? await updateCounterpartyRequest({ data: prepare(), id: Number(counterparty?.id) }).unwrap()
          : await createCounterpartyRequest(prepare()).unwrap()
        onClose()
      } catch (error) {
        toast.error(error?.cause?.[0]?.cause) || error
      }
    }
  }

  useEffect(() => {
    return () => {
      handleReset()
    }
  }, [])
  return (
    <CounterpartyModalWrapper>
      <ExpenseModalHeader>
        {isValidObject(counterparty)
          ? tr('FRONTEND.COUNTERPARTY.ACTION.UPDATE', 'Update')
          : tr('FRONTEND.COUNTERPARTY.ACTION.NEW', 'New')}{' '}
        {tr('FRONTEND.EXPENSE_MANAGEMENT.TITLE.COUNTERPARTY', 'Counterparty')}
      </ExpenseModalHeader>
      <form onSubmit={handleSubmit}>
        <Input type='text' label={tr('FRONTEND.COUNTERPARTY.COLUMN_NAME', 'Name')} {...registerInput('name')} />
        <Button
          type='submit'
          color='round-primary'
          data-test='create-update-counterpary'
          text={
            isValidObject(counterparty)
              ? tr('FRONTEND.COUNTERPARTY.ACTION.UPDATE', 'Update')
              : tr('FRONTEND.COUNTERPARTY.ACTION.CREATE', 'Create')
          }
          loading={isLoading}
        />
      </form>
    </CounterpartyModalWrapper>
  )
}

export default CounterpartyCreateModal
