import { useUserPermissions } from './useUserPermissions'
import { isOwnerOrAdministrator, selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'

import { MerchantAccessLevelEnum } from 'mmfintech-commons-types'

export const useHasWritePermissions = (accountId?: string | number) => {
  const customerRole = useAppSelector(selectCurrentUserRole)
  const { isLoading, error, permissions } = useUserPermissions()

  if (isOwnerOrAdministrator(customerRole)) return true
  if (isLoading || error) return false

  if (accountId) {
    const permission = permissions?.find(
      (perm: any) => Number(perm.accountId) === Number(accountId) && perm.accessLevel === MerchantAccessLevelEnum.WRITER
    )

    return !!permission
  }

  const permission = permissions?.find((perm: any) => perm.accessLevel === MerchantAccessLevelEnum.WRITER)
  return !!permission
}
