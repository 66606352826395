import styled from 'styled-components'

export const TransactionsContainer = styled.div`
  [data-test='details-button'] {
    border: 1px solid #808080;
    height: 3.4rem;

    color: #afafaf;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;

    @media (min-width: 1200px) {
      //width: 12rem;
      display: none;
    }
  }

  .customize-columns-button {
    width: 100%;
    text-align: right;
    margin-bottom: 2rem;

    @media (min-width: 1200px) {
      display: none;
    }

    .button {
      background: #4f4f4f;
      border-radius: 5px;

      height: 3rem;
      padding: 0 1.8rem 0 1rem;

      color: #eeeeee;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
  }

  .data-table-head {
    .data-table-row {
      .data-table-head-cell {
        border-bottom: 1px solid #808080;

        color: #808080;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 120%;
      }

      .button {
        background: #4f4f4f;
        border-radius: 5px;

        height: 3rem;
        padding: 0 1.8rem 0 1rem;

        color: #eeeeee;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }
    }
  }

  @media (min-width: 1200px) {
    .thin,
    .buttons {
      width: 1%;
      min-width: 12rem;
    }
  }

  .data-table-container {
    .data-table-body {
      .data-table-row {
        background: #000000;

        :hover {
          [data-test='details-button'] {
            display: inline-flex;
          }
        }

        .data-table-cell {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 120%;

          .amount {
            color: #ffffff;
            font-weight: 500;
          }
          &.text-upper {
            text-transform: uppercase;
          }

          .status {
            display: inline-block;
            color: #808080;

            &.status-scheduled,
            &.status-pending {
              color: #ffce32;
            }
            &.status-failed {
              color: #ff0431;
            }
            &.status-processed {
              color: #00b451;
            }

            @media (min-width: 1200px) {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 1.5rem;

              width: 10rem;
              height: 3.2rem;
              border-radius: 4.2rem;

              color: #000000;
              background-color: #808080;

              &.status-scheduled,
              &.status-pending {
                color: #000000;
                background-color: #ffce32;
              }
              &.status-failed {
                color: #000000;
                background-color: #e8112d;
              }
              &.status-processed {
                color: #000000;
                background-color: #00b451;
              }
            }
          }
        }
        .data-table-cell-caption {
          text-transform: none;
        }
      }
    }

    .view-button-cell {
      .button {
        width: 100%;
      }
    }

    @media (min-width: 450px) {
      .from-to {
        align-items: flex-end;
      }

      .view-button-cell {
        .button {
          max-width: 25rem;
          margin: 0 auto;
        }
      }
    }

    @media (min-width: 1200px) {
      .data-table-cell,
      .data-table-head-cell {
        padding-left: 1rem !important;
        padding-right: 1rem !important;

        &.preview-cell {
          width: 10rem !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }

      .data-table-body {
        .data-table-row {
          //cursor: pointer;
          background: transparent;
          border-radius: 0;
          box-shadow: none;
        }
      }

      .view-button-cell {
        display: none;
      }

      .attachment-cell {
        width: 4rem;
        .attachment-text {
          display: none;
        }
      }

      .attachment-cell,
      .arrow-right-cell {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .from-to {
        align-items: flex-start;
      }
    }
  }
`
