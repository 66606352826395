import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import toast from 'react-hot-toast'

import { Button, Checkbox, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import { fixDateOnly, isSafeString, tr, useFormValues } from 'mmfintech-commons'
import {
  useCreateReportMutation,
  useCreateCounterpartyMutation,
  useEditExpenseMutation,
  useCreateExpenseMutation,
  useGetReportQuery,
  useLazyGetReportListQuery,
  useLazyGetCounterpartiesQuery,
  useCurrencies,
  useAppSelector,
  selectCurrentUserId
} from 'mmfintech-backend-api'

import { PreviewFile } from '../components/PreviewFile'
import { SelectCreateLabel } from '../components/SelectCreateLabel'
import { ExpenseDragAndDrop } from '../components/ExpenseDragAndDrop'

import { CounterpartyStatusEnum, Expense, ReportStateEnum } from 'mmfintech-commons-types'

import IconClock from '../../../images/icons/clock.svg?react'
import CalendarIcon from '../../../images/icons/filter-calendar.svg?react'
import UploadExpenseIcon from '../../../images/expenses/upload-expense-modal-icon.svg?react'

import { DocumentContainer } from '../components/styles/PreviewFile.styled'
import {
  ExpenseModalBody,
  ExpenseModalContainer,
  ExpenseModalFooter,
  ExpenseModalHeader,
  ExpenseModalLeftSection,
  ExpenseModalRightSection,
  GroupInputs,
  DragAndDropContainerInModal,
  DragAndDropImageContainer
} from './styles/expenseModal.styled'

interface IAddExpenseModal {
  expense?: Expense
  rawFileInfo?: File
  onClose?: () => void
  reportId?: string
  reload?: () => void
}

const creatableInputsProps = {
  async: true,
  type: 'select',
  defaultOptions: true,
  cacheOptions: true,
  isCreatable: true,
  noOptionsMessage: () => <></>,
  formatCreateLabel: (inputText: string) => <SelectCreateLabel text={inputText} />,
  placeholder: 'Type to add' //todo localize
}

export const AddExpenseModal: React.FC<IAddExpenseModal> = ({ expense, rawFileInfo, onClose, reportId, reload }) => {
  const params = useParams<{ reportId: string }>()
  const [selectedReportId, setSelectedReportId] = useState()
  const { data: report } = useGetReportQuery({ id: Number(params.reportId || reportId || selectedReportId) })

  const userId = useAppSelector(selectCurrentUserId)

  const { currencyOptions } = useCurrencies()
  const [getReports] = useLazyGetReportListQuery()
  const [getCounterparties] = useLazyGetCounterpartiesQuery({
    refetchOnReconnect: true,
    refetchOnFocus: true
  })
  const [createExpense, { error: createError, reset: resetCreate, isLoading: createExpenseLoading }] =
    useCreateExpenseMutation()
  const [editExpense, { error: editError, reset: resetEdit, isLoading: editExpenseLoading }] = useEditExpenseMutation()
  const [createReportRequest, { isLoading: isCreateReportLoading, isSuccess: isSuccessCreateReport }] =
    useCreateReportMutation()
  const [
    createCounterpartyRequest,
    { isLoading: isCreateCounterpartyLoading, isSuccess: isSuccessCreateCounterparty }
  ] = useCreateCounterpartyMutation()
  const [fileObjectUrl, setFileObjectUrl] = useState<Expense['displayFile'] | null>(expense?.displayFile)

  const formValues = useFormValues({
    id: { value: expense?.id },
    file: { required: true, value: rawFileInfo || expense?.displayFile },
    reportId: { required: true, value: report ? { label: report?.name, value: report?.id } : null },
    currency: { required: true, value: expense?.currency },
    amount: { required: true, value: expense?.amount },
    dateOfPayment: { required: true, value: expense?.dateOfPayment },
    reimbursement: { required: true, value: !!expense?.reimbursement },
    counterpartyId: {
      required: true,
      validation: 'safe-string',
      value: expense?.counterpartyId
        ? {
            label: expense?.counterpartyName,
            value: expense?.counterpartyId
          }
        : null
    },
    invoiceNumber: { validation: 'safe-string', maxLength: 30, value: expense?.invoiceNumber },
    reference: { validation: 'safe-string', maxLength: 100, value: expense?.reference },
    description: { validation: 'safe-string', maxLength: 100, value: expense?.description },
    dueDate: { value: expense?.dueDate }
  })

  useEffect(() => {
    if (!params.reportId && !reportId && formValues.getValue('reportId')) {
      setSelectedReportId(formValues.getValue('reportId').value)
    }
  }, [formValues.getValue('reportId')])

  const handleSaveClick = async (isNew?: boolean) => {
    try {
      if (formValues.areValid()) {
        const preparedValues = formValues.prepare()

        preparedValues.reportId = preparedValues.reportId?.value
        preparedValues.counterpartyId = preparedValues.counterpartyId?.value
        preparedValues.dateOfPayment = fixDateOnly(preparedValues.dateOfPayment)
        preparedValues.dueDate = fixDateOnly(preparedValues?.dueDate)

        const res = !preparedValues.id
          ? await createExpense({ data: preparedValues as any, reportId: preparedValues.reportId }).unwrap()
          : await editExpense({ data: preparedValues as any, reportId: preparedValues.reportId }).unwrap()

        typeof reload === 'function' && reload()

        if (!isNew && res) {
          preparedValues.id
            ? toast.success(tr('FRONTEND.EXPENSE_MODAL.EDITED', 'Expense successfully edited'))
            : toast.success(tr('FRONTEND.EXPENSE_MODAL.CREATED', 'Expense successfully created'))
          typeof onClose === 'function' && !isNew && onClose()
        }

        return true
      }
    } catch (error) {
      formValues.handleErrors(error)

      return false
    }
  }

  const handleNewClick = async () => {
    const isSuccess = await handleSaveClick(true)

    if (isSuccess) {
      formValues.cleanup(['reportId', 'reimbursement'], true)
      handleRemoveFile()
      toast.success(tr('FRONTEND.EXPENSE_MODAL.CREATED', 'Expense successfully created'))
    }
  }

  const handleSelectFile = (file: File) => {
    setFileObjectUrl({ fileUrl: URL.createObjectURL(file), type: file.type })
    formValues.setValue('file', file)
  }

  const handleRemoveFile = () => {
    setFileObjectUrl(null)
    formValues.setValue('file', null)
  }

  const handleCreateNewCounterpartyAndReport = async (name: string, isReport?: boolean) => {
    if (!isSafeString(name)) {
      toast.error(
        tr(
          'FRONTEND.EXPENSE_MODAL.INVALID_INPUT_ERROR',
          'Invalid input. Field must contain only alphanumeric characters, whitespace and special characters apart from <>`={}|'
        )
      )
      return
    }

    try {
      const res: any = isReport
        ? await createReportRequest({ name }).unwrap()
        : await createCounterpartyRequest({ name }).unwrap()
      isReport
        ? toast.success(tr('FRONTEND.EXPENSE_MODAL.REPORT_CREATE_SUCCESS', 'Report created successfully'))
        : toast.success(tr('FRONTEND.EXPENSE_MODAL.COUNTERPARTY_CREATE_SUCCESS', 'Counterparty created successfully'))

      return { label: res?.name, value: res?.id }
    } catch (err) {
      formValues.handleErrors(err)

      toast.error(err.error)
    }
  }

  const getOptions = async (inputValue: string, isReport?: boolean) => {
    try {
      if (isSafeString(inputValue)) {
        const payload = { size: 25, name: `%${inputValue}%` }
        const res: any = isReport
          ? await getReports({
              ...payload,
              deleted: false,
              userId: userId,
              states: [ReportStateEnum.NEW, ReportStateEnum.EDIT]
            }).unwrap()
          : await getCounterparties({
              ...payload,
              type: 'MERCHANT',
              includeExpenseMetrics: false,
              state: CounterpartyStatusEnum.ACTIVE,
              isOwner: false
            }).unwrap()

        return dataToOptions(res)
      }
      return []
    } catch (err) {
      toast.error(err.error)
    }
  }

  const dataToOptions = (data: any) => {
    return data?.content?.map((item: any) => ({ label: item.name, value: item.id }))
  }

  useEffect(() => {
    return () => {
      resetCreate()
      resetEdit()
    }
  }, [])

  return (
    <ExpenseModalContainer>
      <ExpenseModalHeader>
        {expense?.id
          ? tr('FRONTEND.EXPENSES.EXPENSE_MODAL.EDIT_EXPENSE', 'Edit Expense')
          : tr('FRONTEND.EXPENSES.EXPENSE_MODAL.CREATE_NEW_EXPENSE', 'Create New Expense')}
      </ExpenseModalHeader>
      <ExpenseModalBody>
        <ExpenseModalLeftSection>
          <Input
            key={`report_${JSON.stringify(isSuccessCreateReport)}`}
            {...creatableInputsProps}
            isLoading={isCreateReportLoading}
            isDisabled={isCreateReportLoading || !!report}
            onCreateOption={(name: string) => handleCreateNewCounterpartyAndReport(name, true)}
            loadOptions={(inputValue: string) => getOptions(inputValue, true)}
            label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.REPORT', 'Report')}
            {...formValues.registerInput('reportId')}
            isCreatable={!report}
            placeholder={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.TYPE_TO_ADD', 'Type to add')}
          />
          <Input
            key={`conterparty_${JSON.stringify(isSuccessCreateCounterparty)}`}
            {...creatableInputsProps}
            isLoading={isCreateCounterpartyLoading}
            isDisabled={isCreateCounterpartyLoading}
            onCreateOption={(name: string) => handleCreateNewCounterpartyAndReport(name)}
            loadOptions={(inputValue: string) => getOptions(inputValue)}
            label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.COUNTERPARTY', 'Counterparty')}
            {...formValues.registerInput('counterpartyId')}
            placeholder={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.TYPE_TO_ADD', 'Type to add')}
          />

          <GroupInputs>
            <Input
              type='number'
              {...formValues.registerInput('amount')}
              placeholder='0.00'
              label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.AMOUNT', 'Amount')}
            />
            <Input
              type='select'
              {...formValues.registerInput('currency')}
              placeholder='Select'
              label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.CURRENCY', 'Currency')}
              options={currencyOptions()}
            />
          </GroupInputs>

          <Checkbox
            {...formValues.registerCheckbox('reimbursement')}
            label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.REIMBURSEMENT', 'Reimbursement')}
            className='reimbursement-checkbox'
          />

          <GroupInputs>
            <Input
              type='date'
              {...formValues.registerInput('dateOfPayment')}
              placeholder='Select'
              label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.DATE', 'Date')}
              icon={<CalendarIcon />}
              minDate={new Date(report?.fromDate)}
              maxDate={formValues.getValue('dueDate') || new Date(report?.toDate)}
              parentClassName='calendar-input'
              dateFormat='MM/dd/yyyy'
            />
            <Input
              type='date'
              {...formValues.registerInput('dueDate')}
              placeholder='Select'
              label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.DUE_DATE', 'Due Date')}
              icon={<IconClock />}
              minDate={formValues.getValue('dateOfPayment') || new Date(report?.fromDate)}
              maxDate={new Date(report?.toDate)}
              parentClassName='calendar-input'
              dateFormat='MM/dd/yyyy'
            />
          </GroupInputs>

          <Input
            type='text'
            {...formValues.registerInput('invoiceNumber')}
            placeholder='0123456'
            label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.INVOICE_NUMBER', 'Invoice Number')}
          />
          <Input
            type='text'
            {...formValues.registerInput('description')}
            placeholder={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.TYPE_TO_ADD', 'Type to add')}
            label={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.DESCRIPTION', 'Description')}
          />
        </ExpenseModalLeftSection>

        <ExpenseModalRightSection>
          <ExpenseDragAndDrop
            file={fileObjectUrl}
            Component={ExpenseModalUploadContainer}
            onSelected={handleSelectFile}
            onRemoveFile={handleRemoveFile}
            error={formValues.getError('file')}
          />
        </ExpenseModalRightSection>
      </ExpenseModalBody>
      <ErrorDisplay error={[createError, editError]} />
      <ExpenseModalFooter>
        <Button
          loading={createExpenseLoading || editExpenseLoading}
          text={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.SAVE_CLOSE', 'Save & Close')}
          color='round-alternative'
          data-test='save-edit-expense'
          onClick={() => handleSaveClick()}
        />
        {!expense?.id ? (
          <Button
            loading={createExpenseLoading || editExpenseLoading}
            text={tr('FRONTEND.EXPENSES.EXPENSE_MODAL.SAVE_NEW', 'Save & New')}
            color='round-primary'
            data-test='save-edit-new-expense'
            onClick={handleNewClick}
          />
        ) : null}
      </ExpenseModalFooter>
    </ExpenseModalContainer>
  )
}

const ExpenseModalUploadContainer = ({ onRemoveAll, onFileUpload, file, removeFile, error }) => {
  return file ? (
    <PreviewFile removeFile={removeFile} file={file} />
  ) : (
    <DocumentContainer>
      <DragAndDropContainerInModal
        $isError={!!error}
        onClick={() => {
          onRemoveAll()
          onFileUpload()
        }}>
        <>
          <DragAndDropImageContainer>
            <UploadExpenseIcon />
          </DragAndDropImageContainer>
          <div>
            {!window.matchMedia('(pointer: coarse)').matches
              ? tr('FRONTEND.EXPENSES.EXPENSE_MODAL.DRAG_DROP', 'Drag & Drop')
              : tr('FRONTEND.EXPENSES.EXPENSE_MODAL.CLICK_TO_UPLOAD', 'Click to Upload')}
            {!window.matchMedia('(pointer: coarse)').matches ? (
              <div className='drop-receipt-text'>
                {tr('FRONTEND.EXPENSES.EXPENSE_MODAL.OR_CLICK', 'or click here to upload')}
              </div>
            ) : null}
            <ErrorDisplay error={error} hideIcon />
          </div>
        </>
      </DragAndDropContainerInModal>
    </DocumentContainer>
  )
}
