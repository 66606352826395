import styled from 'styled-components'

import HamburgerMenuIcon from '../../images/icons/hamburger.svg'

export const HeaderWrapper = styled.div`
  grid-area: header;

  display: grid;
  grid-template-columns: 1fr minmax(32rem, 149rem) 1fr;

  margin: 0;
  padding: 0;
  width: 100%;

  z-index: 99;

  color: #ffffff;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 147%;
  letter-spacing: 0.01em;

  background: #000000;
  box-shadow: none;

  &.initial {
    grid-template-columns: 1fr minmax(32rem, 117rem) 1fr;
    box-shadow: none;
  }

  .overlay {
    z-index: 10;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(18px);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

  a {
    white-space: nowrap;
  }
`

export const BrandLogoWrapper = styled.div`
  a {
    padding-left: 0;
  }
`

export const HeaderContainer = styled.nav<{ maxMobileWidth: number }>`
  grid-column: 2 / 3;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  padding: 0 1rem;

  @media (min-width: ${({ maxMobileWidth }) => `${maxMobileWidth}px`}) {
    padding: 0 0.5rem;
  }
`

export const HeaderInnerContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  .dropdown-anchor {
    position: relative;
    height: 100%;

    .arrow-expenses {
      margin-left: 8px;
      transform: rotate(180deg);

      &.active {
        transform: rotate(0deg);
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    text-align: center;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    padding: 0 1.5rem;

    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    cursor: pointer;

    &.active-tab {
      color: #ffffff;
      font-weight: bold;
      border-bottom: 3px solid #ffffff;
    }
    &.disabled {
      pointer-events: none;
    }

    color: #828282;
    :hover {
      color: #ffffff;
    }
  }
`

export const HeaderRightContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  .dropdown-anchor {
    position: relative;
    height: 100%;
  }
`

export const Hamburger = styled.div`
  padding-right: 1rem;
  @media (min-width: 320px) {
    padding-right: 1.5rem;
  }

  div.title {
    color: #000000;
  }

  a {
    margin-bottom: 0;
    padding: 1.6rem 2.5rem;

    &.active-tab {
      border-bottom: none;
    }
  }
`

export const HamburgerIcon = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  position: relative;
  padding: 0;
  z-index: 10;
  border: none;
  cursor: pointer;
  background: transparent;

  :focus {
    outline: none;
  }

  svg:hover > * {
    fill: #bdbdbd;
  }

  .menu-icon {
    width: 2.5rem;
    height: 2.5rem;
    //prettier-ignore
    background: url("${HamburgerMenuIcon}") center center no-repeat;

    :hover {
      //prettier-ignore
      background: url("${HamburgerMenuIcon}") center center no-repeat;
    }
  }
`

export const HamburgerMenu = styled.nav<{ open: boolean; maxMobileWidth: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.9rem;

  position: absolute;
  top: 0;
  right: 0;
  padding: 3rem;
  width: 22rem;
  height: 100vh;
  z-index: 10000;

  background: #000000;
  box-shadow: rgb(150, 150, 150, 0.5) 0 0 10px;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};

  a,
  div.title {
    color: #ffffff;
    &.active-tab {
      color: #ffffff;
      font-weight: 500;
    }
    :hover {
      color: #ffffff;
    }
  }

  @media (max-width: ${({ maxMobileWidth }) => `${maxMobileWidth}px`}) {
    align-items: flex-start;
    justify-content: start;
    padding: 2rem;

    a {
      padding: 0;
      height: 6rem;
      text-align: left;
      font-size: 1.6rem;
      line-height: 1.9rem;

      &.sign-up {
        margin: 2rem 0 0;
      }
    }
  }

  @media (max-width: 515px) {
    height: 100vh;
    .dummy {
      width: 100%;
      justify-content: space-around;
      align-items: center;
    }
  }
`
