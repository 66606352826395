import { useMemo, useState } from 'react'

import { Button } from 'mmfintech-portal-commons'
import { ModalDialog } from '../../components'
import { ReportEditModal } from './report/ReportEditModal'
import { AddEditExpenseButton } from './report/reportHeaders/AddEditExpenseButton'
import { HeaderContainer, VerticallyCentered } from './ReportsTableHeaderStyled'

import { tr } from 'mmfintech-commons'
import { selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'

import { CustomerRoleEnum } from 'mmfintech-commons-types'

import AddReportIcon from '../../images/expenses/add-report.svg?react'

export const ReportsViewHeader = ({ pagination }) => {
  const [isReportCreateModalVisible, setIsReportCreateModalVisible] = useState<boolean>(false)

  const customerRole = useAppSelector(selectCurrentUserRole)

  const isApproverOrSubmitter = useMemo(
    () => customerRole === CustomerRoleEnum.SUBMITTER || customerRole === CustomerRoleEnum.APPROVER,
    [customerRole]
  )

  const handleCreateModalOpen = () => setIsReportCreateModalVisible(true)
  const handleCreateModalClose = () => {
    pagination.reload()
    setIsReportCreateModalVisible(false)
  }

  return (
    <HeaderContainer className={isApproverOrSubmitter ? 'padding-top-SA' : ''}>
      <div className='left-wrapper'></div>
      <div className='right-wrapper'>
        <AddEditExpenseButton reload={() => pagination.reload()} />
        <VerticallyCentered>
          <Button
            color='round-alternative'
            className='action-report-button'
            text={tr('FRONTEND.EXPENSE_MANAGEMENT.ADD_REPORT', 'Add Report')}
            icon={<AddReportIcon />}
            data-test='button-add-report'
            onClick={handleCreateModalOpen}
          />
        </VerticallyCentered>
      </div>

      <ModalDialog
        options={{ size: 'lg', closeOnEscape: false, closeOnClickOutside: false }}
        visible={isReportCreateModalVisible}
        content={<ReportEditModal onClose={handleCreateModalClose} />}
        onClose={handleCreateModalClose}
      />
    </HeaderContainer>
  )
}
