import styled from 'styled-components'

import SpeedyBackground1 from '../../images/speedy-background-1.png'
import SpeedyBackground2 from '../../images/speedy-background-2.png'
import SpeedyBackground3 from '../../images/speedy-background-3.png'
import SpeedyBackground4 from '../../images/speedy-background-4.png'

export const ContentWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(320px, 1490px) 1fr;
  grid-template-rows: 6rem auto;
  grid-template-areas: 'header header header' 'left-column main-content right-column';
  grid-row-gap: 0;

  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  font-family: 'Helvetica Now Display', sans-serif;
  background-color: #202020;

  @media (min-width: 1175px) {
    grid-template-rows: 7rem auto;
  }

  & > div:first-of-type {
    animation: fadein 0.75s;
  }

  .fluid & {
    grid-row-gap: 0;

    @media (min-width: 900px) {
      grid-row-gap: 0;
    }

    @media (min-width: 1175px) {
      grid-template-rows: 9rem auto;
      grid-row-gap: 0;
    }
  }

  .round-square-container {
    background: #202020;
    border-radius: 1rem;
    color: #ffffff;
    padding: 3rem 5rem;

    @media (max-width: 1024px) {
      padding: 2rem 3rem;
    }
  }

  .label-image-link {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;

    color: #a3b8c2;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.7rem;
    cursor: pointer;

    .icon,
    img,
    svg {
      margin-right: 0.8rem;
    }
  }

  .speedy-background-1,
  .speedy-background-2,
  .speedy-background-3,
  .speedy-background-4 {
    background-color: #000000;
    background-repeat: repeat-y;
    background-position: center top;
    background-size: cover;
  }
  .speedy-background-1 {
    //prettier-ignore
    background-image: url("${SpeedyBackground1}");
  }
  .speedy-background-2 {
    //prettier-ignore
    background-image: url("${SpeedyBackground2}");
  }
  .speedy-background-3 {
    //prettier-ignore
    background-image: url("${SpeedyBackground3}");
  }
  .speedy-background-4 {
    //prettier-ignore
    background-image: url("${SpeedyBackground4}");
  }

  .input-hint {
    color: #828282;
    font-size: 1.2rem;
    font-style: italic;
    margin: -0.5rem 0 2rem;
  }

  ${'' /* react-datepicker styling */}
  .react-datepicker {
    background-color: #ffffff;
    border-color: #ffffff;
    border-radius: 8px;
    box-shadow: none;
    font-size: 1.4rem;
    padding: 0 0 1rem;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__header {
    background-color: #ffffff;
    border-bottom-color: #ffffff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 2rem 2rem 0;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: #000000;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
  }
  .react-datepicker__day-names {
    margin-top: 2rem;
    margin-bottom: -8px;
  }
  .react-datepicker__day-name {
    color: #000000;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 3rem;
    margin: 2px 4px;
    width: 3rem;
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }
  .react-datepicker__navigation--next {
    top: 1.5rem;
    right: 1rem;
  }
  .react-datepicker__navigation--previous {
    top: 1.5rem;
    left: 1rem;
  }
  .react-datepicker__day {
    border: 1px solid transparent;
    border-radius: 50%;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 3rem;
    margin: 2px 4px;
    width: 3rem;
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    border-top-color: #ffffff;
  }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
    border-top-color: #ffffff;
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: #ffffff;
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: #ffffff;
  }
  .react-datepicker__navigation-icon::before {
    border-color: #000000;
    border-top-width: 2px;
    border-right-width: 2px;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    background-color: #000000;
    border: 1px solid #000000;
    color: #ffffff;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover,
  .react-datepicker__month--selected:hover,
  .react-datepicker__month--in-selecting-range:hover,
  .react-datepicker__month--in-range:hover,
  .react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter--in-selecting-range:hover,
  .react-datepicker__quarter--in-range:hover {
    background-color: transparent;
  }
  .react-datepicker__day--outside-month {
    color: #afafaf;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: transparent;
    border: 1px solid #dcdfe3;
    color: #000000;
  }

  .trs-2 {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .pagination-container > li {
    background: #111010;
  }
`
