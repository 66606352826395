export const WhiteOutlineStyle = {
  container: (provided: any) => ({
    ...provided,
    height: '3.6rem',
    width: '100%',
    fontFamily: 'inherit',
    fontSize: '1.4rem',
    lineHeight: '120%'
  }),
  control: (provided: any) => ({
    ...provided,
    color: '#ffffff',
    cursor: 'pointer',
    border: '1px solid #ffffff',
    borderRadius: '4.2rem',
    background: 'transparent',
    boxShadow: 'none',
    minWidth: '10rem',
    ':hover': {
      borderColor: '#ffffff !important'
    }
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#ffffff',
    width: '100%',
    textAlign: 'center'
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#ffffff',
    ':hover': {
      color: '#ffffff'
    }
  }),
  menu: (provided: any) => ({
    ...provided,
    background: '#ffffff',
    border: 'none',
    borderRadius: '1.8rem',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.7)',
    padding: '0.5rem',
    marginTop: '2rem',
    ':hover': {
      border: 'none !important'
    }
  }),
  option: (provided: any, { value }) => ({
    ...provided,
    color: '#000000',
    cursor: 'pointer',
    fontSize: '1.4rem',
    lineHeight: '170%',
    background: '#ffffff',
    borderBottom: value === 'allTime' || value === '' ? '1px solid #eeeeee' : 'none',
    ':hover': {
      color: '#ffffff',
      background: '#000000',
      borderRadius: '1rem'
    }
  })
}

export const WhiteSolidStyle = {
  container: (provided: any) => ({
    ...provided,
    fontFamily: 'inherit',
    fontSize: '1.8rem'
  }),
  control: (provided: any) => ({
    ...provided,
    color: '#ffffff',
    cursor: 'pointer',
    paddingLeft: '0.5rem',
    paddingRight: '1rem',
    border: 0,
    boxShadow: 'none',
    borderRadius: '0.5rem',
    background: '#f5f5f5',
    height: '5rem',
    width: '100%',
    ':hover': {
      border: '#ffffff !important'
    }
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#000000'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#000000'
  }),
  menu: (provided: any) => ({
    ...provided,
    background: '#ffffff',
    border: 'none',
    borderRadius: '0.5rem',
    padding: '1rem',
    ':hover': {
      border: 'none !important'
    }
  }),
  option: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    color: '#000000',
    fontSize: '1.8rem',
    lineHeight: '140%',
    background: '#ffffff',
    ':hover': {
      color: '#ffffff',
      background: '#000000',
      borderRadius: '1rem'
    }
  })
}
