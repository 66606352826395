import styled from 'styled-components'

export const AccountCreateWrapper = styled.div`
  width: 96vw;
  max-width: 73rem;
  position: relative;
  background: #1a1a1a;

  padding: 3rem 2rem;

  @media (min-width: 560px) {
    width: 80vw;
  }
  @media (min-width: 940px) {
    padding: 4rem 5rem 5rem;
  }
`

export const AccountCreateTitle = styled.div`
  color: #ffffff;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  margin-bottom: 3rem;
`

export const AccountCreateForm = styled.form`
  margin: 0;
`

export const AccountCreateFormInner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0;

  @media (min-width: 940px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
    row-gap: 0;

    .merge-2 {
      grid-column: 1 / span 2;
    }
  }
`

export const AccountCreateButtons = styled.div`
  margin-top: 3rem;

  .button {
    width: 13rem;
    height: 4rem;

    font-size: 14px;
    font-weight: 400;
    line-height: 140%;

    padding-right: 1rem;
  }
`
