import styled from 'styled-components'

export const ConfirmationWrapper = styled.div`
  position: relative;
  background: #1a1a1a;
`

export const ConfirmationContainer = styled.div<{ small: boolean }>`
  width: ${small => (small ? 'auto' : '100vw')};
  max-width: 94rem;
  padding: ${small => (small ? '4rem 5rem' : '6rem 2rem 2rem')};

  ${small =>
    !small &&
    `
  @media (min-width: 480px) {
    padding: 6rem 5rem 3rem;
  }

  @media (min-width: 1024px) {
    padding: 0 5rem;
    height: 45rem;
  }`}

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;

  color: #afafaf;
  font-size: ${small => (small ? '1.6rem' : '1.8rem')};
  font-style: normal;
  font-weight: normal;
  line-height: 140%;

  .button {
    height: ${small => (small ? '4rem' : '7rem')};
    max-width: ${small => (small ? '20rem' : '26rem')};
    border-radius: 4.2rem;
  }
`

export const ConfirmationContent = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  .highlight {
    color: white;
  }
`

export const ConfirmationTitle = styled.div`
  color: #ffffff;
  font-size: 6.4rem;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 2rem;
`
