import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { GlobalContext } from 'mmfintech-commons'
import { IbanRequestModal } from '../views/account/IbanRequestModal'

import { paths, selectMerchantCapabilities, useAppSelector, useOnboardingBannerQry } from 'mmfintech-backend-api'

export const useOnboarding = () => {
  const { modalShow } = useContext(GlobalContext)

  const history = useHistory()

  const capabilities = useAppSelector(selectMerchantCapabilities)
  const { ibanEligible } = capabilities || {}

  const startSumSubOnboarding = () => {
    history.push(paths.onboarding.selectType())
  }

  const { startOrContinueOnboarding } = useOnboardingBannerQry({
    startOnboarding: startSumSubOnboarding
  })

  const shouldRequestIban = () => ibanEligible

  const requestIban = () => {
    modalShow({
      options: {
        closeOnClickOutside: false,
        size: 'auto'
      },
      content: <IbanRequestModal />
    })
  }

  return {
    requestIban,
    shouldRequestIban,
    startOrContinueOnboarding
  }
}
