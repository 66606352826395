import styled from 'styled-components'

import { DragAndDropContainer } from './expenseList.styled'

export const ExpenseModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 72rem;

  padding: 4rem;
  justify-content: flex-end;
  align-items: flex-start;

  background: #1a1a1a;
  gap: 3rem;
  flex-shrink: 0;
  border-radius: 0.6rem;

  .reimbursement-checkbox {
    margin-bottom: 2.4rem;
  }

  .drop-target {
    display: flex;
    flex: 1;
    height: auto;
  }

  @media (max-width: 768px) {
    min-width: auto;
  }

  .react-datepicker-popper {
    z-index: 3;
  }

  .calendar-input > div {
    cursor: pointer;
    background: #000;
    border: 0 solid #000;
    .date-input {
      cursor: pointer;
      color: #afafaf;
    }

    &.error {
      border-color: ${({ theme }) => theme.input.container.errorBorderColor};
      border-width: ${({ theme }) => theme.input.container.errorBorderWidth};
    }
  }
`

export const ExpenseModalBody = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 30rem));
  width: 100%;
  gap: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
export const ExpenseModalLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  .input-wrapper {
    max-width: 30rem;
    margin-bottom: 1.2rem;

    label {
      line-height: 120%;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
export const ExpenseModalRightSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const ExpenseModalHeader = styled.h3`
  margin: 0;
  font-size: 3rem;
  font-weight: 500;
  color: #fff;
`

export const ExpenseModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2.4rem;
`

export const GroupInputs = styled.div`
  display: flex;
  gap: 2rem;

  .input-wrapper {
    max-width: 14rem;
  }
`
export const DragAndDropContainerInModal = styled(DragAndDropContainer)<{ $isFileLoaded?: boolean; $isError: boolean }>`
  grid-area: pdf-viewer;

  flex-direction: column;
  flex: 1;
  padding: ${({ $isFileLoaded }) => ($isFileLoaded ? '0' : '6rem')};

  font-size: 1.6rem;
  font-weight: 400;
  color: #fff;
  text-align: center;
  border: none;

  border: ${({ $isError }) => {
    return $isError ? '1px dashed red' : '1px dashed #4f4f4f'
  }};

  .drop-receipt-text {
    font-size: 1.2rem;
    font-weight: 400;
    color: #808080;
  }
`
export const DragAndDropImageContainer = styled.div`
  margin-bottom: 8rem;
`
export const ExpenseDetailsUpper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    gap: 2rem;
    flex-flow: column nowrap;
  }
`
export const ExpenseDetailsControls = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  font-size: 1.8rem;
  color: #fff;
  line-height: 90%;

  svg {
    cursor: pointer;
  }
  .inverted {
    transform: rotate(180deg);
  }
`
export const ExpenseDetails = styled.div`
  .amount-label {
    color: #afafaf;
    font-size: 1.4rem;
    line-height: 120%;
  }
  .amount {
    color: #fff;
    font-size: 2rem;
    line-height: 100%;
    font-weight: 700;
    margin: 0;
  }

  .details-container {
    margin-top: 20px;
    border-radius: 6px;
    border: 1px solid #4f4f4f;
    padding: 1.7rem 1.1rem;

    display: flex;
    flex-direction: column;
    gap: 1.4rem;

    .details {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 120%;
      .label {
        flex: 1;
        color: #afafaf;
      }
      .value {
        flex: 1;
        color: #fff;
        overflow-wrap: anywhere;
      }
    }
  }
`

export const ReimburasbleContainer = styled.div`
  padding: 6px 12px;
  border: 1px solid #eeeeee;
  color: #eeeeee;
  border-radius: 36px;
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 12px;

  svg {
    padding-right: 4px;
    width: 10px;
    height: 10px;
  }
`
