import styled from 'styled-components'

interface SidebarProps {
  isCompletelyClosed?: boolean
  $visible?: boolean
  $isLeft?: boolean
}

export const SidebarWrapper = styled.div<SidebarProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  opacity: ${props => (props.$visible ? '1' : '0')};
  visibility: ${props => (props.$visible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
`

export const SidebarContainer = styled.div<SidebarProps>`
  width: 450px;
  height: 100%;
  /* padding: 20px; */
  background: #151515;
  overflow-y: auto;
  box-sizing: border-box;
  transform: ${props => (props.$visible ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-out;

  &.size-auto {
    width: auto;
  }

  &.size-large {
    width: 400px;
  }

  &.size-small {
    width: 200px;
  }

  &.size-extra-large {
    width: 600px;
  }

  &.centered {
    align-self: center;
  }

  &.horizontal {
    width: 100%;
    height: auto;
  }

  &.transparent {
    background: transparent;
  }

  &.overflow-none {
    overflow: hidden;
  }
`
