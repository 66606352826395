import styled from 'styled-components'

export const AmountInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  position: relative;
`

export const AmountInputLabel = styled.label`
  color: #ffffff;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  margin-bottom: 1.5rem;
`

export const AmountInputContainer = styled.div`
  border-bottom: 1px solid #ffffff;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  width: 100%;

  &.read-only {
    border-bottom-color: #808080;
  }

  &.error {
    border-bottom-color: #ee2b2b;
  }
`

export const AmountInputStyled = styled.input`
  width: 100%;
  padding: 0;
  border: none;
  outline: none;

  background: transparent;

  color: #ffffff;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  .read-only & {
    color: #808080;
  }

  &::-webkit-input-placeholder {
    color: #808080;
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
  &:-ms-input-placeholder {
    color: #808080;
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
  &::placeholder {
    color: #808080;
    font-family: 'Helvetica Now Display', sans-serif;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
`

export const AmountInputError = styled.div`
  display: block;
  text-align: left;
  margin-bottom: 1rem;

  color: #ee2b2b;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: normal;
  line-height: 110%;
`

export const AmountsInputLimits = styled.div`
  color: #afafaf;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  b {
    color: #808080;
    font-weight: 400;
  }

  span {
    margin-left: 2rem;
    :first-of-type {
      margin-left: 0;
    }
  }
`
