import styled from 'styled-components'

export const VerificationContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4rem;

  width: 100%;

  @media (min-width: 640px) {
    justify-content: center;
    margin-top: 0;
  }
`

export const OtpWrapper = styled.div`
  width: 100%;
  max-width: 59rem;
  background: #1a1a1a;
  padding: 7rem 2rem 3rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @media (min-width: 640px) {
    padding: 5rem 7rem 7rem;
    align-items: flex-start;
  }
`

export const OtpTitle = styled.div`
  color: #ffffff;
  font-size: 4.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-align: center;

  margin-bottom: 2rem;

  @media (min-width: 640px) {
    font-size: 6.4rem;
    text-align: left;
  }
`

export const OtpSubtitle = styled.div`
  color: #afafaf;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  text-align: center;

  span {
    color: #ffffff;
    display: block;
    font-weight: bold;
    overflow-wrap: anywhere;
    margin-top: 1rem;
    margin-bottom: 5rem;
  }

  @media (min-width: 640px) {
    text-align: left;
  }
`

export const ResendLinkWrapper = styled.div`
  color: #000000;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.1rem;

  text-align: center;
  margin-top: 1.5rem;

  a {
    font-weight: bold;
  }
`

export const Countdown = styled.div`
  text-align: center;
  margin: 1.5rem 0;

  color: #afafaf;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.1rem;

  &.expired {
    color: #ff0431;
  }
`
