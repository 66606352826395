import styled from 'styled-components'

export const CurrencyIconWrapper = styled.span<{ width: string; height: string }>`
  display: inline-block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;

  &.card,
  &.bordered {
    border: 1px solid #ddd;
  }

  & > img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }
`
