import styled from 'styled-components'

export const InlineInputStyled = styled.input`
  outline: none;
  border: 2px solid orange;
  border-radius: 0;
  height: 5rem;

  color: #ffffff;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    box-shadow: 0 0 0 3rem #1a1a1a inset !important;
    -webkit-box-shadow: 0 0 0 3rem #1a1a1a inset !important;
    border: none !important;
    outline: none !important;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    box-shadow: 0 0 0 3rem #1a1a1a inset !important;
    -webkit-box-shadow: 0 0 0 3rem #1a1a1a inset !important;
    -webkit-text-fill-color: #ffffff !important;
    caret-color: #ffffff !important;
    background: transparent !important;
    outline: none !important;
    border: none !important;
  }
`
