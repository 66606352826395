import styled from 'styled-components'

export const CustomMenuIconWrapper = styled.div`
  height: 5rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.8rem 0 1.6rem;

  border: 1px solid #dfe8ec;
  border-radius: 1rem;
  cursor: pointer;

  span {
    margin: 0 1.2rem;
  }
`
