import styled from 'styled-components'

export const NoAvailableAccountsWrapper = styled.div`
  border: 1px solid #4f4f4f;
  border-radius: 4px;

  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;

  .left {
    display: flex;
    align-items: center;
    gap: 2rem;
    color: #808080;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  .button {
    max-width: 15rem;
    height: 40px;
  }
`
