import { useHistory } from 'react-router-dom'

import { Button } from 'mmfintech-portal-commons'
import { OnboardingBanner } from '../../../components'
import { MerchantNotOnboardedStyled } from './MerchantNotOnboardedModal.styled'

import { tr } from 'mmfintech-commons'
import { paths } from 'mmfintech-backend-api'

import Icon from '../../../images/icons/deposit.svg?react'

type MerchantNotOnboardedModal = {
  onClose: () => void
}

export const MerchantNotOnboardedModal = ({ onClose }: MerchantNotOnboardedModal) => {
  const history = useHistory()

  const handleSpeedyPayClick = () => {
    history.push(paths.invoices.create())
    onClose()
  }

  return (
    <MerchantNotOnboardedStyled data-test='enotas-not-onboarded-warning'>
      <div className='title'>{tr('FRONTEND.ENOTAS.NOT_SUPPORTED.TITLE', 'Not Available')}</div>

      <div>
        <OnboardingBanner button />
      </div>

      <div className='buttons'>
        <Button
          type='button'
          color='round-primary'
          icon={<Icon className='mr-1 ml-2' />}
          text={tr('FRONTEND.ENOTAS.NOT_SUPPORTED.SPEEDYPAY_BUTTON', 'Speedy Pay')}
          onClick={handleSpeedyPayClick}
          data-test='button-speedy-pay'
        />
      </div>
    </MerchantNotOnboardedStyled>
  )
}
