import { useGetMerchantUserAccessQuery, useGetMyPermissionsQuery } from 'mmfintech-backend-api'

export const useUserPermissions = (props?: { customerId?: number }) => {
  const { customerId } = props || {}

  const {
    data: customerPermissions,
    isFetching: customerPermissionsFetching,
    error: customerPermissionsError
  } = useGetMerchantUserAccessQuery(
    {
      userId: customerId
    },
    { skip: !customerId }
  )

  const {
    data: myPermissions,
    isFetching: myPermissionsFetching,
    error: myPermissionsError
  } = useGetMyPermissionsQuery(undefined, { skip: !!customerId })

  return {
    permissions: !customerId ? myPermissions : customerPermissions,
    isLoading: !customerId ? myPermissionsFetching : customerPermissionsFetching,
    error: !customerId ? myPermissionsError : customerPermissionsError
  }
}
