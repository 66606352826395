import { initialize } from 'mmfintech-backend-api'

initialize(import.meta.env)

const settings = {
  cookieDomain: '.speedy.io',
  customerServiceUrl: 'https://globalhelp.speedy.io/hc/pt-br',
  landingPageUrl: 'https://speedy.io',
  languages: ['en', 'es', 'pt', 'pl', 'no', 'hu', 'ja'],

  defaultUploadInvoiceAcceptType: ['pdf', 'jpg', 'jpeg', 'png'],
  defaultUploadInvoiceMaxFileSize: 1024 * 1024
}

export default settings
