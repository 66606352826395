import { ProgressBarWithInfoWrapper, ProgressBarInfoWrapper } from './SpendingLimitBar.styled'

import { formatMoney, tr } from 'mmfintech-commons'
import { selectCurrentUserId, useAppSelector, useGetUserExpenseLimitsQuery } from 'mmfintech-backend-api'

export const SpendingLimitBarUser = () => {
  const currentUserId = useAppSelector(selectCurrentUserId)

  const { data: expenseLimits } = useGetUserExpenseLimitsQuery(
    { merchantUserId: currentUserId },
    {
      skip: !currentUserId
    }
  )

  if (!expenseLimits) {
    return null
  }

  const { availableMonthlyVolume, monthlyVolume, currency, spentMonthlyVolume } = expenseLimits || {}

  const availableMonthlyVol = availableMonthlyVolume > 0 ? availableMonthlyVolume : 0
  const progressPercentage = monthlyVolume ? 100 - (availableMonthlyVol / monthlyVolume) * 100 : 100

  return (
    <div>
      <ProgressBarInfoWrapper>
        <div>
          <span className='info-text'>
            {monthlyVolume
              ? tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.MONEY_AVAILABLE', 'Money available:')
              : tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.MONEY_SPENT', 'Money spent:')}
          </span>
          {formatMoney(monthlyVolume ? availableMonthlyVolume : spentMonthlyVolume, currency)}
        </div>
        <div>
          <span className='info-text'>
            {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.MONTHLY_LIMIT', 'Monthly limit:')}{' '}
          </span>
          {monthlyVolume ? formatMoney(monthlyVolume, currency) : <span className='infinity'>∞</span>}
        </div>
      </ProgressBarInfoWrapper>
      <ProgressBarWithInfoWrapper percentage={progressPercentage} monthlyVolume={monthlyVolume}>
        <div className='progress-bar'>
          <div>{monthlyVolume ? Math.round(progressPercentage) : 0}%</div>
        </div>
      </ProgressBarWithInfoWrapper>
    </div>
  )
}
