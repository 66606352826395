import styled from 'styled-components'

export const AccessDeniedContainer = styled.div`
  grid-area: main-content;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 15rem;
`

export const AccessDeniedContent = styled.div`
  width: 100%;
  max-width: 50rem;
  text-align: center;

  .title {
    font-size: 3.6rem;
    color: white;
  }

  p {
    color: white;
    font-size: 1.8rem;
    font-weight: 500;
  }

  .button {
    max-width: 36rem;
    margin-top: 2rem;

    color: black;
    background-color: white;
    font-size: 1.8rem;

    :focus,
    :hover {
      background-color: rgba(255, 255, 255, 0.8);
      color: black;
    }
  }
`
