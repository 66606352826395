import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import {
  AccountCreateButtons,
  AccountCreateForm,
  AccountCreateFormInner,
  AccountCreateTitle,
  AccountCreateWrapper
} from './AccountCreateModal.styled'

import { tr } from 'mmfintech-commons'
import { useAccountCreateQuery } from 'mmfintech-backend-api'

import RightArrow from '../../../images/icons/right-arrow-in-circle.svg?react'

type AccountCreateModalProps = {
  filteredCurrencies?: any
  onSuccess?: (currency: string, accountId: number) => void
}

export const AccountCreateModal = ({ filteredCurrencies, onSuccess }: AccountCreateModalProps) => {
  const { currencies, formValues, createAccountError, createAccountFetching, handleSubmit } = useAccountCreateQuery({
    filteredCurrencies,
    onSuccess
  })

  return (
    <AccountCreateWrapper data-test='create-account-modal'>
      <AccountCreateTitle className='title'>
        {tr('FRONTEND.ACCOUNTS.CREATE.TITLE', 'Add new account')}
      </AccountCreateTitle>

      <AccountCreateForm noValidate>
        <AccountCreateFormInner>
          <Input
            type='select'
            data-test='currency'
            label={tr('FRONTEND.ACCOUNTS.CREATE.CURRENCY_LABEL', 'Currency')}
            options={currencies}
            onChange={(name: string, value: string) => formValues.setValue(name, value)}
            {...formValues.registerShort('currency')}
          />

          <Input
            type='text'
            data-test='account-name'
            label={tr('FRONTEND.ACCOUNTS.CREATE.NAME_LABEL', 'Account name')}
            {...formValues.registerInput('name')}
          />
        </AccountCreateFormInner>

        <ErrorDisplay error={createAccountError} />

        <AccountCreateButtons>
          <Button
            type='button'
            color='round-primary'
            text={tr('FRONTEND.ACCOUNTS.CREATE.SUBMIT_BUTTON', 'Add')}
            icon={<RightArrow />}
            iconPosition='right'
            loading={createAccountFetching}
            data-test='button-submit'
            onClick={handleSubmit}
          />
        </AccountCreateButtons>
      </AccountCreateForm>
    </AccountCreateWrapper>
  )
}
