import styled from 'styled-components'

export const CookieConsentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  z-index: 20;
  width: 58.5rem;
  max-width: calc(100vw - 6rem);

  position: fixed;
  right: 3rem;
  bottom: 3rem;

  background: #ffffff;
  border-radius: 1rem;
  padding: 3.5rem 4rem;

  color: #000000;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
`

export const ConsentHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  font-size: 3.6rem;
  line-height: 90%;
  margin-bottom: 2.5rem;

  img {
    margin-bottom: 1rem;
  }

  @media (min-width: 500px) {
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    img {
      margin-right: 3rem;
      margin-bottom: 0;
    }
  }
`

export const ConsentContent = styled.div``

export const ConsentFooter = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  margin-top: 2.5rem;

  button {
    height: 5rem;
    width: 100%;
    font-size: 1.6rem;

    margin-top: 1rem;
    :last-of-type {
      margin-top: 0;
    }

    &.manage {
      background: transparent;
      border: 1px solid #000000;
      color: #000000;
      box-shadow: none;
      outline: none;

      :focus,
      :hover {
        background: #f7f7f7;
      }
    }
    &.consent {
      color: #ffffff;

      :focus,
      :hover {
        background: #333333;
      }
    }
  }

  @media (min-width: 500px) {
    flex-flow: row nowrap;

    button {
      width: 18rem;
      margin-top: 0;
      margin-right: 2rem;
      :last-of-type {
        margin-right: 0;
      }
    }
  }
`
