import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import cn from 'classnames'

import { ModalContainer, ModalWrapper } from './ModalDialog.styled'

import { useModal } from 'mmfintech-commons'

import CloseIcon from '../../images/icons/modal-close.svg?react'

type ModalDialogOptions = {
  closeIconPosition?: 'default' | 'speedy-special'
  closeOnClickOutside?: boolean
  closeOnEscape?: boolean
  hideCloseIcon?: boolean
  scrollable?: boolean
  maxWidth?: number
  size?: string
  transparent?: boolean
  onClose?: () => void
  overflow?: 'none' | 'visible'
  customSize?: number
  halfScreenOnMobile?: boolean
}

type ModalDialogProps = {
  content?: ReactNode
  options?: ModalDialogOptions
  visible: boolean
  onClose: () => void
}

export const ModalDialog = ({ content, options, visible, onClose }: ModalDialogProps) => {
  const { ref, width, checkWidth, handleClose, overflowing, modalOptions, clickOutsideRef } = useModal({
    options,
    visible,
    onClose
  })

  const location = useLocation()

  useEffect(() => {
    visible && onClose()
    // eslint-disable-next-line
  }, [location.pathname])

  if (visible) {
    return (
      <ModalWrapper className={cn({ overflowing: overflowing || width < checkWidth })} ref={ref}>
        <ModalContainer
          className={cn('icon-' + modalOptions.closeIconPosition, { scrollable: modalOptions.scrollable })}
          checkWidth={checkWidth}>
          <div className='popup-main-content' ref={clickOutsideRef}>
            {content}
            {!modalOptions.hideCloseIcon && (
              <div className='popup-close-button-inline' onClick={onClose}>
                <CloseIcon />
              </div>
            )}
          </div>
          {!modalOptions.hideCloseIcon && (
            <div className='popup-close-button' data-test='popup-close-button' onClick={handleClose}>
              <CloseIcon />
            </div>
          )}
        </ModalContainer>
      </ModalWrapper>
    )
  }

  return null
}
