import styled from 'styled-components'

import { AmountContainer } from '../Commons'

export const AmountPreviewWrapper = styled(AmountContainer)`
  padding: 2rem 0 0;
`

export const AmountLabel = styled.div``

export const AmountValue = styled.div`
  margin-top: 1.5rem;
  font-size: 24px;
  font-weight: 400;
  line-height: 90%;
`

export const AmountPreviewContainer = styled.div`
  width: 100%;
  padding: 0 3rem 2.5rem;
`

export const FeeAmountContainer = styled.div`
  color: #808080;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: normal;

  border-radius: 0 0 7px 7px;
  margin-top: 1rem;
`

export const NetAmountContainer = styled.div`
  width: 100%;
  padding: 2.5rem 3rem;
  background: rgba(0, 0, 0, 0.25);
`

export const NetAmountLabel = styled.div`
  color: #808080;
  margin-left: 0;
  margin-right: 0;
`

export const NetAmountValue = styled.div`
  color: #ffffff;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  margin-top: 1.5rem;
`
