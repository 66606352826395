import styled from 'styled-components'

export const CityNotSupportedStyled = styled.div`
  width: 100vw;
  max-width: 59rem;
  position: relative;

  background: #1a1a1a;
  padding: 5rem 7rem 4rem;

  color: #ffffff;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;

  .title {
    font-size: 4rem;
    line-height: 100%;
    margin-bottom: 2rem;
  }

  .buttons {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-top: 4rem;

    .button {
      max-width: 40rem;
    }
  }
`
