import styled from 'styled-components'
import { ReportTableWrapper } from '../../reports/ReportTable.styled'

export const CounterpartyTableWrapper = styled(ReportTableWrapper)`
  table {
    @media (min-width: 800px) {
      td,
      th {
        padding: 2rem 15px !important;
      }
    }
  }
`
