import React from 'react'
import { Link } from 'react-router-dom'

import cn from 'classnames'

import { CustomUserMenuIcon } from './CustomUserMenuIcon'
import { DropDownMenuContainer, DropDownMenuIcon, DropDownMenuWrapper } from './UserDropDownMenu.styled'

import { useAppDispatch } from '../../hooks'
import { tr, useDropDownMenu } from 'mmfintech-commons'
import {
  api,
  getMerchantAccountTypeName,
  useGetMerchantQuery,
  useLazyLogoutQuery,
  useLazyCardLogoutQuery
} from 'mmfintech-backend-api'

type UserDropDownMenuProps = {
  customUserMenuIcon?: boolean
  customMenuItem?: boolean
  customLogoutButton?: boolean
  customUserMenuHeader?: boolean
  UserMenuItems?: any
}

export const UserDropDownMenu = ({
  customUserMenuIcon,
  customMenuItem,
  customLogoutButton,
  customUserMenuHeader,
  UserMenuItems
}: UserDropDownMenuProps) => {
  const { data: merchant } = useGetMerchantQuery()

  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const dispatch = useAppDispatch()

  const [logout] = useLazyLogoutQuery()
  const [cardLogout] = useLazyCardLogoutQuery()

  const handleLogout = async () => {
    await logout().unwrap()
    dispatch(api.util.resetApiState())
  }

  const handleCardLogout = async () => {
    try {
      await cardLogout().unwrap()
    } catch (_err) {
      // nothing
    }
  }

  const handleLogoutClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    hideDropDown()
    void handleCardLogout()
    void handleLogout()
  }

  const LogoutMenuItem = () => (
    <Link to='#' className='menu-item logout' onClick={handleLogoutClick}>
      {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
    </Link>
  )

  return (
    <DropDownMenuWrapper>
      {customUserMenuIcon ? (
        <CustomUserMenuIcon merchantName={merchant?.name} onClick={toggleDropDown} />
      ) : (
        <DropDownMenuIcon onClick={toggleDropDown}>
          <span className={cn('menu-icon', 'user-menu-icon', { visible })} />
        </DropDownMenuIcon>
      )}

      {visible && (
        <DropDownMenuContainer className={cn({ custom: customUserMenuIcon })}>
          <div className='triangle' />
          {customUserMenuHeader && (
            <div className='account-info'>
              <span>{merchant?.name}</span>
              <span>{getMerchantAccountTypeName(merchant)}</span>
            </div>
          )}

          <UserMenuItems onClick={hideDropDown} className={cn('menu-item', { selector: customMenuItem })} />
          {!customLogoutButton ? (
            <div className='menu-footer'>
              <LogoutMenuItem />
            </div>
          ) : (
            <LogoutMenuItem />
          )}
        </DropDownMenuContainer>
      )}
    </DropDownMenuWrapper>
  )
}
