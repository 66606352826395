import styled from 'styled-components'
import { ReportsWrapper } from '../../dashboard/ExpensesDashboard/ExpensesDashboard.styled'
import { HeaderContainer } from '../ReportsTableHeaderStyled'
import { ExpenseModalHeader } from '../expenses/styles/expenseModal.styled'

export const ReportWrapper = styled(ReportsWrapper)`
  display: flex;
  gap: 1.5rem;
`

export const ReportHeaderContainer = styled(HeaderContainer)`
  padding: 4rem 0;
  position: relative;
  align-items: center;

  h1 {
    padding: 0 2rem 0 0;
    margin-bottom: 0;
    border-bottom: 1.5px solid transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50rem;
  }

  .report-input-wrapper > div {
    box-sizing: border-box;

    padding: 0;

    border: none;
    border-bottom: 1.5px solid #fff;
    border-radius: 0;
  }
  .report-input-wrapper {
    margin: 0;
    box-sizing: border-box;
  }
  .report-name-input {
    height: 100%;
    display: block;
    box-sizing: border-box;

    padding: 0 2rem 1rem 0;

    font-size: 5rem;
    font-weight: 500;
  }
`
export const ReportSubheaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 1.4rem;
`

export const LeftReportSubheader = styled.div`
  display: flex;
  gap: 3.125rem;
  height: 60px;

  @media (max-width: 800px) {
    gap: 1.125rem;
    flex-direction: column;
    height: auto;
  }

  .submitter,
  .approver {
    border: 1px solid #4f4f4f;
    border-radius: 36px;
    padding: 6px 16px;
    width: fit-content;
  }

  & > .row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .label {
      font-size: 1.4rem;
      color: #808080;
    }

    .value {
      font-size: 1.6rem;
      color: #fff;
      padding-bottom: 6px;
    }
  }
`
export const ReportSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  .label {
    display: block;
    text-align: right;
    font-size: 1.4rem;
    font-weight: 400;
    color: #808080;
    margin-bottom: 0.7rem;
  }
  .amount-value {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
    text-align: right;
  }
`
export const ReportEditModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 4rem;
  justify-content: center;

  background: #1a1a1a;
  gap: 3rem;
  border-radius: 0.6rem;

  .input-wrapper {
    margin-bottom: 1.2rem;

    label {
      line-height: 120%;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  button {
    width: fit-content !important;
    margin: 0 auto;
  }

  .calendar-input > div {
    cursor: pointer;
    background: #000;
    border: 0 solid #000;
    .date-input {
      cursor: pointer;
      color: #afafaf;
    }

    &.error {
      border-color: ${({ theme }) => theme.input.container.errorBorderColor};
      border-width: ${({ theme }) => theme.input.container.errorBorderWidth};
    }
  }
`
export const ReportEditModalHeader = styled(ExpenseModalHeader)``
