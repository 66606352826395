import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 25rem;
  //margin-bottom: 1.5rem;
  margin: 0 auto;

  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 4rem 1fr 5rem;

  .inner {
    display: inline-flex;
    align-items: center;
    height: 100%;
  }

  .icon {
    display: inline-flex;
  }

  .rate {
    width: 100%;
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: center;

    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;

    .currency {
      color: #808080;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      text-transform: capitalize;
    }
  }

  .change {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-align: center;

    display: inline-block;
    width: 100%;

    padding: 2px 5px;
    border-radius: 4px;

    &.positive {
      background: rgba(0, 180, 81, 0.2);
      border: 1px solid rgba(0, 180, 81, 0.7);
      color: #0bda5e;
    }
    &.negative {
      background: rgba(255, 0, 0, 0.2);
      border: 1px solid rgba(255, 0, 0, 0.5);
      color: #ff4c4d;
    }
  }
`
