import { ReactNode } from 'react'

import cn from 'classnames'

import {
  CheckBoxGroupFooter,
  CheckBoxGroupHeader,
  CheckBoxGroupItem,
  CheckBoxGroupWrapper
} from './CheckBoxGroup.styled'

import { tr } from 'mmfintech-commons'

interface ItemType {
  name: string
  label: string
  localizationKey?: string
}

interface CheckBoxGroupProps {
  footer?: ReactNode
  header?: ReactNode
  items?: ItemType[]
  checked: (name: string) => boolean
  setChecked: (name: string) => void
}

export const CheckBoxGroup = ({ footer, header, items, checked, setChecked }: CheckBoxGroupProps) => (
  <CheckBoxGroupWrapper onClick={e => e.stopPropagation()}>
    {header && <CheckBoxGroupHeader>{header}</CheckBoxGroupHeader>}

    {Array.isArray(items) &&
      items.map((item, index) => {
        const { name, label, localizationKey } = item
        return (
          <CheckBoxGroupItem key={index}>
            <span>{tr(localizationKey, label)}</span>
            <span
              className={cn('checkbox', { checked: checked(name) })}
              onClick={() => setChecked(name)}
              data-test={`toggle-${name}`}
            />
          </CheckBoxGroupItem>
        )
      })}

    {footer && <CheckBoxGroupFooter>{footer}</CheckBoxGroupFooter>}
  </CheckBoxGroupWrapper>
)
