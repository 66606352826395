import React from 'react'
import { useHistory } from 'react-router-dom'

import DeleteReportButton from './components/DeleteReportButton'

import { DataTable } from 'mmfintech-portal-commons'
import { NoReportsComponent } from './NoReportsComponent'
import { ReportStatusBadge } from './components/ReportStatusBadge'
import { ReportTableWrapper, SubmitterBadge } from './ReportTable.styled'

import {
  isOwnerOrAdministrator,
  paths,
  selectCurrentUserId,
  selectCurrentUserRole,
  useAppSelector
} from 'mmfintech-backend-api'
import { formatDateToUserLocale, formatMoney, isValidArray, tr } from 'mmfintech-commons'

import { ExpenseManagementReport, ReportStateEnum } from 'mmfintech-commons-types'

const { Table, Row, HeadCell, Head, Body, Cell } = DataTable

type ReportTableProps = {
  data: ExpenseManagementReport[]
  isApproverTable?: boolean
  reloadPagination: () => void
}
const actionStatuses = [ReportStateEnum.NEW, ReportStateEnum.EDIT]

export const ReportsTable: React.FC<ReportTableProps> = ({ data, isApproverTable, reloadPagination }) => {
  const history = useHistory()

  const userId = useAppSelector(selectCurrentUserId)
  const customerRole = useAppSelector(selectCurrentUserRole)

  const getTotalReportAmount = (report: ExpenseManagementReport) => {
    return formatMoney(report.totalAmount, report.currency)
  }

  const handleReportRowClick = (report: ExpenseManagementReport) => {
    if (
      // Check if the user is able to access payments page
      (report.state === ReportStateEnum.APPROVED ||
        report.state === ReportStateEnum.COMPLETELY_PAID ||
        report.state === ReportStateEnum.PARTIALLY_PAID ||
        report.state === ReportStateEnum.UNPAID) &&
      isOwnerOrAdministrator(customerRole)
    ) {
      history.push(paths.expenseManagement.payments(report?.id))
      return
    }

    history.push(paths.expenseManagement.report(report.id))
  }

  return (
    <ReportTableWrapper>
      <Table>
        <Head>
          <Row className='headRow'>
            {isApproverTable && <HeadCell colSpan={2}>{tr('FRONTEND.REPORTS.COLUMN_SUBMITTER', 'Submitter')}</HeadCell>}
            <HeadCell colSpan={2}>{tr('FRONTEND.REPORTS.COLUMN_NAME', 'Name')}</HeadCell>
            <HeadCell>{tr('FRONTEND.REPORTS.COLUMN_PERIOD', 'Period')}</HeadCell>
            <HeadCell>{tr('FRONTEND.REPORTS.COLUMN_DESCRIPTION', 'Description')}</HeadCell>
            <HeadCell>{tr('FRONTEND.REPORTS.COLUMN_EXPENSES', 'Expenses')}</HeadCell>
            <HeadCell>{tr('FRONTEND.REPORTS.COLUMN_STATUS', 'Status')}</HeadCell>
            <HeadCell className='text-right'>{tr('FRONTEND.REPORTS.COLUMN_TOTAL', 'Total')}</HeadCell>
          </Row>
        </Head>

        {isValidArray(data) ? (
          <Body className='main-tbody'>
            {data.map((report: ExpenseManagementReport) => {
              return (
                <Row
                  key={report.id}
                  data-test={`open-report-${report.id}`}
                  onClick={() => handleReportRowClick(report)}>
                  {isApproverTable && (
                    <Cell colSpan={2} caption={tr('FRONTEND.REPORTS.COLUMN_SUBMITTER', 'Submitter')}>
                      <SubmitterBadge>{report.submitter}</SubmitterBadge>
                    </Cell>
                  )}
                  <Cell className='report-name' caption={tr('FRONTEND.REPORTS.COLUMN_NAME', 'Name')} colSpan={2}>
                    {report.name}
                  </Cell>
                  <Cell caption={tr('FRONTEND.REPORTS.COLUMN_PERIOD', 'Period')}>
                    {formatDateToUserLocale(report.fromDate)} - {formatDateToUserLocale(report.toDate)}
                  </Cell>
                  <Cell caption={tr('FRONTEND.REPORTS.COLUMN_DESCRIPTION', 'Description')}>{report.description}</Cell>
                  <Cell caption={tr('FRONTEND.REPORTS.COLUMN_EXPENSES', 'Expenses')}>{report.expenses.length}</Cell>
                  <Cell caption={tr('FRONTEND.REPORTS.COLUMN_STATUS', 'Status')}>
                    <ReportStatusBadge status={report.state} />
                  </Cell>
                  <Cell className='text-right' caption={tr('FRONTEND.REPORTS.COLUMN_TOTAL', 'Total')}>
                    {getTotalReportAmount(report)}
                  </Cell>
                  <Cell>
                    {actionStatuses.includes(report.state) && userId == report.userId && (
                      <DeleteReportButton
                        data-test='delete-report-button'
                        report={report}
                        reloadPagination={reloadPagination}
                      />
                    )}
                  </Cell>
                </Row>
              )
            })}
          </Body>
        ) : (
          <NoReportsComponent title='No Reports' />
        )}
      </Table>
    </ReportTableWrapper>
  )
}
