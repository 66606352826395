import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { OnboardingBanner, SelectInput, WhiteOutlineStyle } from '../../../../components'
import {
  MainPaneButtons,
  MainPaneContainer,
  MainPaneHeader,
  MainPaneWrapper,
  StyledButton
} from './InvoicesPane.styled'

import { formatMoney, tr } from 'mmfintech-commons'
import { periodType, periodOptions } from 'mmfintech-backend-api'
import { paths, selectMerchantCurrency, useAppSelector, useFetchDashboardQuery } from 'mmfintech-backend-api'

import TotalAmountIcon from '../../../../images/icons/invoices-total-amount.svg?react'
import PaidInvoicesIcon from '../../../../images/icons/invoices-paid.svg?react'
import OpenInvoicesIcon from '../../../../images/icons/invoices-open.svg?react'
import OverdueInvoicesIcon from '../../../../images/icons/invoices-overdue.svg?react'

export const InvoicesPane = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<string>(periodType.MONTH)

  const merchantCurrency = useAppSelector(selectMerchantCurrency)
  const { data: dashboard } = useFetchDashboardQuery(null)

  const history = useHistory()

  const { invoices } = dashboard || ''
  const { weeklySummary, monthlySummary, allTimeSummary } = invoices || {}

  const getAmount = (amountType: string) => {
    switch (selectedPeriod) {
      case periodType.ALL:
        if (allTimeSummary && allTimeSummary[amountType]) {
          return formatMoney(allTimeSummary[amountType].amount, allTimeSummary[amountType].currency)
        }
        break

      case periodType.WEEK:
        if (weeklySummary && weeklySummary[amountType]) {
          return formatMoney(weeklySummary[amountType].amount, weeklySummary[amountType].currency)
        }
        break

      case periodType.MONTH:
        if (monthlySummary && monthlySummary[amountType]) {
          return formatMoney(monthlySummary[amountType].amount, monthlySummary[amountType].currency)
        }
        break

      default:
      // nothing
    }

    return formatMoney(0, merchantCurrency)
  }

  return (
    <MainPaneWrapper>
      <MainPaneHeader>
        <MainPaneContainer>
          <div className='title'>{tr('FRONTEND.INVOICES.LIST.TITLE', 'Invoices')}</div>

          <SelectInput
            id='period'
            name='period'
            className='mr-2 selected-period'
            value={selectedPeriod}
            onChange={(_name, value: string) => setSelectedPeriod(value)}
            options={periodOptions()}
            applyStyles={WhiteOutlineStyle}
            disableSearch={true}
            hideErrorLine
            hideLabel
          />
        </MainPaneContainer>

        <OnboardingBanner button className='dashboard' />
      </MainPaneHeader>

      <MainPaneButtons>
        <StyledButton
          className='magenta-shadow'
          data-test='total-amount-button'
          onClick={() => history.push(paths.invoices.list())}>
          <TotalAmountIcon className='icon' />
          <div className='label'>
            <div className='amount' data-test='total-amount'>
              {getAmount('totalAmount')}
            </div>
            {tr('FRONTEND.DASHBOARD.BOX_TOTAL', 'Total amount')}
          </div>
        </StyledButton>

        <StyledButton
          className='green-shadow'
          data-test='paid-invoices-button'
          onClick={() => history.push(paths.invoices.listByStatus('PAID'))}>
          <PaidInvoicesIcon className='icon' />
          <div className='label'>
            <div className='amount' data-test='paid-amount'>
              {getAmount('paidAmount')}
            </div>
            {tr('FRONTEND.DASHBOARD.BOX_PAID', 'Paid')}
          </div>
        </StyledButton>

        <StyledButton
          className='yellow-shadow'
          data-test='open-invoices-button'
          onClick={() => history.push(paths.invoices.listByStatus('OPENED'))}>
          <OpenInvoicesIcon className='icon' />
          <div className='label'>
            <div className='amount' data-test='open-amount'>
              {getAmount('openAmount')}
            </div>
            {tr('FRONTEND.DASHBOARD.BOX_OPEN', 'Open')}
          </div>
        </StyledButton>

        <StyledButton
          className='red-shadow'
          data-test='overdue-invoices-button'
          onClick={() => history.push(paths.invoices.listByStatus('OVERDUE'))}>
          <OverdueInvoicesIcon className='icon' />
          <div className='label'>
            <div className='amount' data-test='overdue-amount'>
              {getAmount('overdueAmount')}
            </div>
            {tr('FRONTEND.DASHBOARD.BOX_OVERDUE', 'Overdue')}
          </div>
        </StyledButton>
      </MainPaneButtons>
    </MainPaneWrapper>
  )
}
