import { motion } from 'framer-motion'
import styled from 'styled-components'

export const StyledTab = styled.div<{ $isActive: boolean }>`
  position: relative; // Added to ensure the z-index works correctly
  z-index: 1; // Ensure the tab content is above the pseudo-element
  flex: 1; // Distribute space evenly between tabs
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  color: ${({ $isActive }) => ($isActive ? 'white' : '#888')};

  text-decoration: none !important;

  box-sizing: border-box;
  padding: 0px 2rem 2rem 2rem;
  border-radius: 1rem;
`

export const StyledTabs = styled.div`
  position: relative; // Added for positioning the pseudo-element
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  border-radius: 1.2rem;
  width: 100%;

  font-size: 1.4rem;

  @media (max-width: 320px) {
    min-width: 26rem;
    width: 90%;
  }

  @media (max-width: 500px) {
    min-width: 30rem;
    width: 90%;
  }
`

export const AnimatedBg = styled(motion.div)`
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  background-color: #fff;
  min-height: 0.1rem;
  box-shadow: 0px 4px 7px 0px rgba(182, 179, 213, 0.28);
  border-radius: 1rem;
  z-index: 0; // Ensure it's below the tab content
`

export const TabsWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;

  justify-content: center;

  @media (max-width: 480px) {
    width: 100%;
    margin: 2rem 0;
    padding-left: 0;
    padding-right: 0;
  }
  border-bottom: 1px solid #888;
  @media (max-width: 320px) {
    font-size: 14px;
  }
`
