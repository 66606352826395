import { Button } from 'mmfintech-portal-commons'
import { SendSuccessStyled } from './SendSuccessModal.styled'

import { tr } from 'mmfintech-commons'

type SendSuccessModalProps = {
  onClose: () => void
}

export const SendSuccessModal = ({ onClose }: SendSuccessModalProps) => (
  <SendSuccessStyled data-test='enotas-success-dialog'>
    <div className='title'>{tr('FRONTEND.ENOTAS.SUCCESS.TITLE', 'Success')}</div>

    <div>
      {tr('FRONTEND.ENOTAS.SUCCESS.MESSAGE', 'Nota Fiscal sent for issuance. You can track the status in Invoices.')}
    </div>

    <div className='buttons'>
      <Button
        type='button'
        color='primary'
        text={tr('FRONTEND.BUTTONS.OK', 'OK')}
        onClick={onClose}
        className='mt-4'
        style={{ maxWidth: '17rem' }}
        data-test='button-ok'
      />
    </div>
  </SendSuccessStyled>
)
