import { getCurrencyImageUrl } from 'mmfintech-commons'

import { CurrencyIconWrapper } from './CurrencyIcon.styled'
import { configuration } from 'mmfintech-backend-api'

interface CurrencyIconProps {
  currency: string
  width?: string
  height?: string
  className?: string
}

export const CurrencyIcon = ({ currency, width = '3rem', height = '3rem', className }: CurrencyIconProps) => (
  <CurrencyIconWrapper width={width} height={height} className={className}>
    <img src={`${configuration.getBaseUrl()}${getCurrencyImageUrl(currency, 'svg')}`} alt={currency} />
  </CurrencyIconWrapper>
)
