import { useState } from 'react'

import { PaginationSetupData, tr, useDelayedExecute, useFilter, usePaginationQuery } from 'mmfintech-commons'
import { useLazyGetCounterpartiesQuery } from 'mmfintech-backend-api'
import { CounterpartyStatusEnum } from 'mmfintech-commons-types'
import { Pagination } from 'mmfintech-portal-commons'

import { CounterpartyViewHeader } from './elements/CounterpartyViewHeader'
import CounterpartyTable from './elements/CounterpartyTable'
import { TableLoader, Tabs } from '../../components'

import { CounterpartiesWrapper } from './styles/counterpartyDashboard.styled'

interface ExtendedPaginationParams extends PaginationSetupData {
  archived?: boolean
}

const CounterpartyDashboard = () => {
  const tabConfig = [
    {
      title: tr('FRONTEND.COUNTERPARTY.TABS.ACTIVE', 'Active'),
      name: 'active'
    },
    {
      title: tr('FRONTEND.COUNTERPARTY.TABS.ARCHIVED', 'Archived'),
      name: 'archived'
    },
    {
      title: tr('FRONTEND.COUNTERPARTY.TABS.ALL', 'All'),
      name: 'all'
    }
  ]

  const [tab, setTab] = useState(tabConfig[0].name)
  const [getCounterparties, { data, isLoading, isFetching }] = useLazyGetCounterpartiesQuery()

  const filterValues = useFilter(
    {
      state: CounterpartyStatusEnum.ACTIVE,
      isOwner: false
    },
    [],
    [],
    () => {
      delayedReload.execute()
    },
    true
  )

  const delayedReload = useDelayedExecute({
    name: 'delayedFiltersReload',
    onExecute: () => pagination.reload({ page: 0 })
  })

  const prepareFilter = (params = null) => ({
    ...params,
    ...filterValues.prepare(),
    includeExpenseMetrics: true
  })

  const pagination = usePaginationQuery({
    rowsPerPage: 7,
    reload: async (params: ExtendedPaginationParams, onSuccess) => {
      try {
        const res: any = await getCounterparties(prepareFilter(params)).unwrap()

        if (res) {
          onSuccess(res)
        }
      } catch (_err) {}
    }
  })

  const handleTabChange = (selectedTab: string) => {
    if (selectedTab !== tab) {
      setTab(selectedTab)
      let fetchArchived: string
      if (selectedTab === 'archived') {
        fetchArchived = CounterpartyStatusEnum.ARCHIVED
      } else if (selectedTab === 'active') {
        fetchArchived = CounterpartyStatusEnum.ACTIVE
      }
      filterValues.set('state', fetchArchived)
      filterValues.reload()
    }
  }

  return (
    <CounterpartiesWrapper>
      <CounterpartyViewHeader />
      <Tabs config={tabConfig} currentTab={tab} setTab={handleTabChange} />
      {isLoading || isFetching ? (
        <TableLoader />
      ) : (
        <>
          <CounterpartyTable data={data?.content || []} />
          <Pagination {...pagination.register()} />
        </>
      )}
    </CounterpartiesWrapper>
  )
}

export default CounterpartyDashboard
