import styled from 'styled-components'

export const GlobalWrapperStyled = styled.div`
  grid-row: 2 / 4;
  grid-column: left-column / right-column;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  @media (min-width: 640px) {
    justify-content: center;
    min-height: 100%;
  }

  .container {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0 1rem;

    color: #ffffff;
    font-family: Helvetica Now Display, sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: normal;
    line-height: 2.1rem;
  }

  #info-tooltip {
    background: #2f3032 !important;
    border-radius: 1rem;
    padding: 1.5rem 2.5rem;
    font-family: Helvetica Now Display, sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
    letter-spacing: 0.01em;
    opacity: 1 !important;
    * {
      text-align: left;
    }
  }
`
