import { useLocation, useParams } from 'react-router-dom'
import { useContext } from 'react'

import { selectCurrentUserId, useAppSelector, useGetReportQuery } from 'mmfintech-backend-api'
import { ReportStateEnum } from 'mmfintech-commons-types'
import { GlobalContext, tr } from 'mmfintech-commons'
import { Button } from 'mmfintech-portal-commons'

import { AddExpenseModal } from '../../expenses/AddExpenseModal'

import AddExpenseIcon from '../../../../images/expenses/add-expense.svg?react'

const actionStatuses = [ReportStateEnum.NEW, ReportStateEnum.EDIT]

type TAddEditExpenseButtonProps = {
  reload?: () => void
}

export const AddEditExpenseButton = ({ reload }: TAddEditExpenseButtonProps) => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const { pathname } = useLocation()
  const userId = useAppSelector(selectCurrentUserId)

  const { reportId } = useParams<{ reportId: string }>()
  const { data, isSuccess } = useGetReportQuery({ id: Number(reportId) })
  const { state, userId: reportUserId } = isSuccess && data

  const handleCreateExpenseModalOpen = () => {
    modalShow({
      options: { size: 'lg', closeOnEscape: false, closeOnClickOutside: false },
      content: <AddExpenseModal onClose={() => modalHide()} reportId={reportId} reload={reload} />
    })
  }

  if (reportId && userId !== reportUserId) return null

  if (!(pathname === '/expense-management/reports') && !actionStatuses.includes(state)) {
    return null
  }

  return (
    <>
      <Button
        color='round-alternative'
        className='action-report-button'
        text={tr('FRONTEND.EXPENSE_MANAGEMENT.ADD_EXPENSE', 'Add Expense')}
        icon={<AddExpenseIcon />}
        data-test='button-add-expense-modal'
        onClick={handleCreateExpenseModalOpen}
      />
    </>
  )
}
