import styled from 'styled-components'

const DocumentWrapper = styled.div<{ $VIEWER_WIDTH: number }>`
  display: flex;
  align-items: center;

  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  width: ${props => `${props.$VIEWER_WIDTH}px`};
  height: 100%;
`
const DocumentContainer = styled.div<{ $solidBorder?: boolean }>`
  height: 100%;
  border-width: 1px;
  /* border-style: ${({ $solidBorder }) => ($solidBorder ? 'solid' : 'dashed')}; */
  border-color: #4f4f4f;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
`
const PageFooter = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1.2rem;

  .controls {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    color: white;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;

    svg {
      cursor: pointer;
    }

    .forward-icon {
      transform: rotate(180deg);
    }
  }

  .buttons-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    .action-button {
      min-width: 8rem;
      max-width: 8rem;
      max-height: 3rem;
      color: white;
      font-size: 1.2rem;
      border-radius: 60px;
      background: #4f4f4f;
    }
  }
`

const PageWrapper = styled.div<{ $MIN_VIEWER_HEIGHT: number; $SCALE: number }>`
  height: 100%;
  display: flex;
  justify-content: center;
  max-height: ${props => `${props.$MIN_VIEWER_HEIGHT}px`};
  height: 100%;

  will-change: transform;

  position: relative;
  z-index: 1;
  cursor: ${props => (props.$MIN_VIEWER_HEIGHT > 100 ? 'crosshair' : 'pointer')};

  --zoom: 1;
  --x: 50%;
  --y: 50%;
  transform: scale(var(--zoom));
  transform-origin: var(--x) var(--y);
  overflow: hidden; // Hide overflow during zoom

  &:hover {
    --zoom: ${props => `${props.$SCALE}`}; // Zoom level when hovered
  }
`
export { DocumentContainer, DocumentWrapper, PageFooter, PageWrapper }
