import styled from 'styled-components'

export const UpdateCompanyStyled = styled.div`
  width: 100vw;
  max-width: 94rem;
  position: relative;

  background: #1a1a1a;
  padding: 9rem 3rem 4rem;

  @media (min-width: 800px) {
    padding: 9rem 8rem 8rem;
  }

  .title {
    color: #ffffff;
    font-size: 4.8rem;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 4rem;

    @media (min-width: 800px) {
      font-size: 6.4rem;
      margin-bottom: 7rem;
    }
  }

  .form-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0;

    @media (min-width: 800px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 8rem;
      row-gap: 0;
    }
  }

  .buttons {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 7rem;

    .button {
      height: 7rem;
      border-radius: 4.2rem;
      margin: 0;
      max-width: 26rem;
      font-size: 2rem;
    }
  }
`
