import { useContext } from 'react'

import { GlobalContext } from 'mmfintech-commons'
import {
  CertificateModal,
  CityNotSupportedModal,
  CredentialsModal,
  MerchantNotOnboardedModal,
  SendSuccessModal,
  UpdateCompanyModal
} from '../views/enotas'

import { useDashboardRedirect } from 'mmfintech-backend-api'

type UseEnotasResult = {
  showCityNotSupportedScreen: () => void
  showSuccessScreen: () => void
  uploadCertificate: (onSuccess?: () => void) => void
  updateCredentials: (onSuccess?: () => void) => void
  updateCompany: (onSuccess?: () => void) => void
  notOnboarded: () => void
}

export const useEnotas = (): UseEnotasResult => {
  const { modalHide, modalShow } = useContext(GlobalContext)
  const { redirectToDashboard } = useDashboardRedirect()

  const showCityNotSupportedScreen = (): void => {
    modalShow({
      options: { closeOnClickOutside: false, maxWidth: 590, size: 'medium' },
      content: <CityNotSupportedModal onClose={modalHide} />
    })
  }

  const showSuccessScreen = (): void => {
    modalShow({
      options: { closeOnClickOutside: false, maxWidth: 590, size: 'medium' },
      content: (
        <SendSuccessModal
          onClose={() => {
            modalHide()
            redirectToDashboard()
          }}
        />
      )
    })
  }

  const uploadCertificate = (onSuccess?: () => void): void => {
    modalShow({
      options: { closeOnClickOutside: false, maxWidth: 940, size: 'large' },
      content: (
        <CertificateModal onClose={modalHide} onSuccess={onSuccess} onReject={() => updateCredentials(onSuccess)} />
      )
    })
  }

  const updateCredentials = (onSuccess?: () => void): void => {
    modalShow({
      options: { closeOnClickOutside: false, maxWidth: 940, size: 'large' },
      content: <CredentialsModal onClose={modalHide} onSuccess={onSuccess} />
    })
  }

  const updateCompany = (onSuccess?: () => void): void => {
    modalShow({
      options: { closeOnClickOutside: false, maxWidth: 940, size: 'large' },
      content: <UpdateCompanyModal onClose={modalHide} onSuccess={onSuccess} />
    })
  }

  const notOnboarded = (): void => {
    modalShow({
      options: { closeOnClickOutside: false, maxWidth: 940, size: 'large' },
      content: <MerchantNotOnboardedModal onClose={modalHide} />
    })
  }

  return {
    showCityNotSupportedScreen,
    showSuccessScreen,
    uploadCertificate,
    updateCredentials,
    updateCompany,
    notOnboarded
  }
}
