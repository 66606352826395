import { useEffect, useState } from 'react'

import cn from 'classnames'

import { LanguageMenuContent, LanguageMenuDropDown, MenuWrapper } from './LanguageMenu.styled'

import settings from '../../settings'
import { initializeLanguage, languages, useDropDownMenu } from 'mmfintech-commons'
import {
  isOwnerOrAdministrator,
  selectCurrentUserRole,
  useAppSelector,
  useAuth,
  useGetUserLanguageQuery,
  useSetUserLanguageMutation
} from 'mmfintech-backend-api'

import { LoginStatusEnum } from 'mmfintech-commons-types'

import ArrowDownIcon from '../../images/icons/input-arrow-down.svg?react'
import ArrowUpIcon from '../../images/icons/input-arrow-up.svg?react'
import LanguageIcon from '../../images/icons/input-select-country.svg?react'

type LanguageMenuProps = {
  hamburger?: boolean
}

export const LanguageMenu = ({ hamburger }: LanguageMenuProps) => {
  const userStatus = useAuth()
  const customerRole = useAppSelector(selectCurrentUserRole)

  const [setUserLanguage] = useSetUserLanguageMutation()
  const { data: selectedLanguage } = useGetUserLanguageQuery()

  const [filteredLanguages, setFilteredLanguages] = useState([])

  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const doSelectLanguage = (languageCode: string): void => {
    if (languageCode !== selectedLanguage) {
      if (userStatus === LoginStatusEnum.LOGGED_IN && isOwnerOrAdministrator(customerRole)) {
        setUserLanguage({ language: languageCode })
      }
    }
    hideDropDown()
    initializeLanguage(languageCode)
  }

  const getLanguageName = () => {
    const data = filteredLanguages.find(item => item.code === selectedLanguage)
    return data ? data.name : ''
  }

  useEffect(() => {
    const filtered = settings.languages.map(code => languages.find(v => v.code === code)).filter(v => v != null)
    setFilteredLanguages(filtered)
    // eslint-disable-next-line
  }, [])

  if (!selectedLanguage) {
    return null
  }

  return (
    <MenuWrapper className={cn({ hamburger })} data-test='language-menu-wrapper'>
      <LanguageMenuContent className={cn({ opened: visible })} onClick={toggleDropDown}>
        <span className='icon'>
          <LanguageIcon />
        </span>
        <span className='selected'>{getLanguageName()}</span>
        <span className='arrow'>{visible ? <ArrowUpIcon /> : <ArrowDownIcon />}</span>
      </LanguageMenuContent>

      <LanguageMenuDropDown className={cn({ opened: visible })} data-test='language-menu-content'>
        <ul data-test='language-menu-list'>
          {filteredLanguages?.map(v => (
            <li key={v.code} onClick={() => doSelectLanguage(v.code)} data-test='language-menu-item'>
              {v.name}
            </li>
          ))}
        </ul>
      </LanguageMenuDropDown>
    </MenuWrapper>
  )
}
