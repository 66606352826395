import ReactTooltip from 'react-tooltip'

import { ReactNode } from 'react'
import { GlobalWrapperStyled } from './GlobalWrapper.styled'

type GlobalWrapperProps = {
  type: 1 | 2 | 3 | 4
  children: ReactNode
}

export const GlobalWrapper = ({ type, children }: GlobalWrapperProps) => (
  <GlobalWrapperStyled className={`speedy-background-${type}`}>
    <div className='container'>{children}</div>
    <ReactTooltip id='info-tooltip' type='dark' effect='solid' place='top' multiline={true} isCapture={true} />
  </GlobalWrapperStyled>
)
