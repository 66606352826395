import styled from 'styled-components'

export const DashboardWrapper = styled.div`
  grid-row: 2 / 4;
  grid-column: main-content;
  padding: 0 2.5rem;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;

  @media (min-width: 1400px) {
    display: grid;
    grid-template-columns: 1fr 43rem;
  }
`

export const DashboardContainer = styled.div`
  padding: 10rem 0 4.5rem;

  @media (min-width: 480px) {
    padding-top: 2rem;
  }
  @media (min-width: 900px) {
    padding-top: 3.5rem;
  }
  @media (min-width: 1175px) {
    padding-top: 5rem;
  }
  @media (min-width: 1400px) {
    padding-right: 5rem;
  }
`

export const StaffDashboardWrapper = styled.div`
  .dashboard-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    button {
      width: fit-content;
    }
  }
`
