import styled from 'styled-components'

import InfoImage from '../../images/icons/input-info.svg'

export const GlobalTitle = styled.div`
  color: #ffffff;
  font-size: 4.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-align: center;

  max-width: 110rem;
  margin: 3rem 0 2rem;

  .subtitle {
    display: block;
    font-size: 1.8rem;
    font-weight: normal;
    line-height: 140%;

    margin-top: 1.5rem;
  }

  @media (min-width: 640px) {
    font-size: 6.4rem;
    margin: 9rem 0 7rem;
  }
`

export const CommonPageTitle = styled.div`
  color: #ffffff;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  .subtitle {
    color: #afafaf;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 120%;
    margin-top: 5px;
  }

  &.iban-title {
    font-size: 3.6rem;
    margin-bottom: 4rem;
  }

  @media (min-width: 480px) {
    font-size: 4.8rem;
  }
  @media (min-width: 768px) {
    font-size: 6.4rem;
  }
`

export const CommonSignupContent = styled.form`
  background: #ffffff;
  //backdrop-filter: blur(44px);
  border-radius: 7px;
  padding: 0;

  width: 92vw;
  max-width: 45rem;

  display: flex;
  flex-flow: column nowrap;

  .err-msg-wrapper {
    margin: 0;
    font-size: 1.2rem;
    padding-left: 3rem;
    padding-right: 3rem;

    svg {
      display: none;
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 3rem 3rem 4rem;
  }
`

const errorOptions = {
  iconTheme: { primary: 'red', secondary: 'white' },
  style: {
    color: '#ffffff',
    fontSize: '1.6rem',
    lineHeight: '120%',
    background: '#141414',
    borderRadius: '4rem'
  }
}

const successOptions = {
  iconTheme: { primary: 'green', secondary: 'white' },
  style: {
    color: '#ffffff', //'rgba(255, 255, 255, 0.4)',
    fontSize: '1.6rem',
    lineHeight: '120%',
    background: '#141414',
    borderRadius: '4rem'
  }
}

export const toastOptions = {
  success: successOptions,
  error: errorOptions
}

export const PageTitle = styled.div`
  color: #ffffff;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  .subtitle {
    display: block;
    color: #afafaf;
    font-size: 1.8rem;
    font-weight: normal;
    line-height: 140%;

    margin-top: 1.5rem;
  }
`

export const PageSubtitle = styled.div`
  color: #afafaf;
  font-size: 4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-right: 2rem;
`

export const PageInfoTitleContainer = styled.div`
  display: flex;

  .filter-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: 2rem;

    .select-wrapper {
      min-width: 14rem;
      margin-right: 2rem;
    }
  }

  &.dashboard {
    justify-content: space-between;
    width: 100%;
  }

  @media (min-width: 900px) {
    display: flex;
    flex-flow: row nowrap;

    .filter-container {
      margin-top: 0;
    }
  }

  @media (max-width: 860px) {
    display: flex;
    flex-direction: column;
  }
`

export const PageInfoSubTitle = styled.div`
  color: #afafaf;
  font-size: 1.6rem;
  line-height: 120%;
  margin-top: 0.5rem;
`

export const PageSecondaryTitle = styled.div`
  color: #ffffff;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`

export const AmountContainer = styled.div`
  z-index: 41;
  width: 100%;
  padding: 2rem 3rem 2rem;
  margin-bottom: 2rem;

  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(44px);
  border-radius: 7px;

  color: #ffffff;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  &.select-action {
    z-index: 45;
    padding: 3rem;

    .input-wrapper {
      margin-bottom: 0;
    }
  }

  .select-account-wrapper {
    margin-bottom: 2.5rem;
  }

  .label {
    margin-bottom: 1rem;
  }
  .amount {
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
  }

  .input-wrapper {
    min-height: 4.5rem;

    .input-inner-wrapper {
      padding: 0;

      input {
        font-size: 2.4rem;
        line-height: 90%;

        &::-webkit-input-placeholder {
          font-size: 2.4rem;
          line-height: 90%;
        }
        &:-ms-input-placeholder {
          font-size: 2.4rem;
          line-height: 90%;
        }
        &::placeholder {
          font-size: 2.4rem;
          line-height: 90%;
        }
      }
    }
  }

  .min-max-amounts {
    color: #afafaf;
    font-size: 1.2rem;
    line-height: 90%;
    b {
      color: #808080;
      font-weight: 400;
    }
  }
`

export const EditContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;

  width: 100%;
  padding: 2.5rem 0 4rem;
  &.no-padding {
    padding: 0;
  }

  background: #ffffff;
  backdrop-filter: blur(44px);
  border-radius: 7px;

  .b-b-1 {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 1.5rem;
  }

  .label-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .checkbox-wrapper {
      margin: 0 2rem 1rem;
      display: inline-flex;
      flex-flow: row nowrap;
      align-items: center;
      .info {
        width: 17px;
        height: 17px;
        cursor: pointer;
        //prettier-ignore
        background: url("${InfoImage}") center center no-repeat;
        margin-left: 1rem;
        svg {
          color: #000000;
        }
      }
    }
  }

  .label {
    margin: 0 2rem 1rem;
    color: #000000;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
  }

  .select-wrapper {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .banks-container {
    padding: 0 3rem;
  }

  .buttons {
    width: 100%;
    padding: 0 3rem;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    .button {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 90%;

      margin-bottom: 0.8rem;
      :last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .err-msg-wrapper {
    padding-left: 3rem;
    padding-right: 3rem;
    margin: 2rem 0 0;

    svg {
      display: none;
    }
  }
`

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  padding: 2.5rem 3rem 4rem;

  background: #ffffff;
  backdrop-filter: blur(44px);
  border-radius: 7px;

  color: #000000;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;

  .subtitle {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 90%;
    margin-bottom: 2rem;
  }

  .attachment {
    color: #1c1c1c;
    cursor: pointer;
    text-decoration: underline;
  }

  .row {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 1.5rem;

    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    .label {
      color: #afafaf;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 120%;
    }
    .value {
      overflow-wrap: anywhere;
      word-break: break-all;
    }
  }

  .buttons {
    width: 100%;
    margin-top: 2rem;

    .button {
      font-weight: 700;
      font-size: 1.4rem;
    }
  }
`

export const CenteredMessage = styled.div`
  grid-row: 2 / 4;
  grid-column: left-column / right-column;

  .container {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;

    .content {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      color: #ffffff;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: normal;
      line-height: 120%;

      .title {
        font-size: 6.4rem;
        font-weight: 500;
        line-height: 100%;

        text-align: center;
        max-width: 71rem;
        margin-bottom: 5rem;

        &.fs100 {
          font-size: 10rem;
          line-height: 100%;
        }

        &.w100 {
          max-width: 100rem;
        }
      }

      p,
      .par {
        text-align: center;
        max-width: 55rem;

        margin-bottom: 2rem;
        :last-of-type {
          margin-bottom: 0;
        }

        a {
          color: #ffffff;
          font-weight: 500;
        }
      }
    }
  }
`
