import styled from 'styled-components'

export const CertificateStyled = styled.div`
  width: 100vw;
  max-width: 94rem;
  position: relative;

  background: #1a1a1a;
  padding: 9rem 8rem 8rem;

  color: #ffffff;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;

  .title {
    font-size: 6.4rem;
    line-height: 100%;
    margin-bottom: 5rem;
  }

  .upload-box {
    height: 14rem;
    border: 2px dashed #afafaf;
    border-radius: 8px;
    cursor: pointer;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    color: #afafaf;
    font-size: 1.8rem;
    text-transform: lowercase;

    .file-types {
      font-size: 1.3rem;
    }
  }

  .input-wrapper {
    max-width: 35rem;
  }

  .onboarding-alert {
    width: 50%;
  }
  .buttons {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 4rem;

    .button {
      :first-of-type {
        margin-bottom: 2rem;
      }

      :last-of-type {
        margin-right: 0;
      }
    }

    @media (min-width: 800px) {
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin-top: 7rem;

      .button {
        max-width: 26rem;
        margin: 0 4rem 0 0;

        :first-of-type {
          margin-bottom: 0;
        }

        :last-of-type {
          max-width: 30rem;
        }
      }
    }
  }
`
