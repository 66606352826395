import styled from 'styled-components'

import { FiltersContainer } from '../../views/transactions/Transactions/TransactionsFilter/TransactionsFilter.styled'

const ReportFiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-areas: 'inputs buttons';
  align-items: center;
  margin: 32px 0px 24px 0px;
`

const Filters = styled(FiltersContainer)`
  grid-area: inputs;
  align-items: center;

  [data-test='text-input-search'],
  [data-test='text-input-name'],
  [data-test='text-input-invoiceNumber'] {
    max-width: 23rem;
    div {
      border-color: #fff;
    }
  }

  [data-test='date-input-from'],
  [data-test='date-input-to'],
  [data-test='date-input-dateOfPayment'],
  [data-test='date-input-dueDate'] {
    max-width: 13rem;

    & > div {
      padding-left: 1.5rem;
      padding-right: 1rem;

      cursor: pointer;
      background: #000;
      border: 0 solid #000;

      &.error {
        border-color: ${({ theme }) => theme.input.container.errorBorderColor};
        border-width: ${({ theme }) => theme.input.container.errorBorderWidth};
      }
      .date-input {
        cursor: pointer;
        color: #afafaf;
      }
    }
    .react-datepicker-popper {
      z-index: 2;
    }
  }

  [data-test='text-input-toAmount'],
  [data-test='text-input-fromAmount'] {
    max-width: 10rem;
  }

  [data-test='select-input-currency'],
  [data-test='select-input-status'] {
    & > div {
      cursor: pointer;
      background: #000;
      border-radius: 2rem;
      color: black;
    }
  }
`
const FilterControlsStyle = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  gap: 2rem;
  min-height: 60px;

  button {
    .applied-filters {
      width: 1.6rem;
      height: 1.6rem;

      border-radius: 50%;
      background-color: #fff;

      display: flex;
      justify-content: center;
      align-items: center;

      color: #000;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    &.filters-button-container {
      height: 36px;
      width: 124px;

      .filters-button {
        color: #ffffff;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        gap: 0.625rem;
      }
    }
  }
`

export { ReportFiltersWrapper, Filters, FilterControlsStyle }
